import React, { useContext, useEffect, useState } from "react";
import useTranslations from "../../../hooks/useTranslations";
import CardList from "../../../components/common/CardList";
import { OrganizationsContext } from "../../../context/OrganizationsContext";
import GroupCard from "../../../components/organization/group/GroupCard";
import { ModalContext } from "../../../context/ModalContext";
import GroupForm from "../../../components/organization/group/GroupForm";
import { GroupsContext } from "../../../context/GroupsContext";
import useFilterByText from "../../../hooks/global/useFilterByText";

const OrganizationGroups = () => {
  const translations = useTranslations();
  const [currentGroups, setCurrentGroups] = useState([]);
  const [organizationAdmin, setOrganizationAdmin] = useState(false);

	const { modalComponent, clearModal } = useContext(ModalContext);
	const { createGroup, groups, getGroupsByOrganization } = useContext(GroupsContext);
	const { organization, user_organization } = useContext(OrganizationsContext);

  const { query, setQuery, filterArray } = useFilterByText();

  useEffect(() => {
    handlePermissions();
  }, [user_organization]);

  const handlePermissions = () => {
    const user_type = user_organization?.user_type;
  
    if(user_type?.name !== 'Member') {
      setOrganizationAdmin(true);
    } 
  }

  useEffect(() => {
    getGroupsByOrganization(organization?.organization_id);
  }, [organization]);


  useEffect(() => {
    handleFilterGroups();
  }, [query, groups]);


  const handleFilterGroups = () => {
    const filteredGroups = filterArray(groups, 'name');
    if(query.length > 0) {
      setCurrentGroups(filteredGroups);
    } else {
      setCurrentGroups(groups);
    }
  }

	const handleCreateModal = () => {
    createGroup();
    
    modalComponent(
      "Create Group",
      <GroupForm handleCancel={clearModal}/>
    );
	}

	const renderGroups = () => {
		return currentGroups?.map(group => {
			return(
				<GroupCard 
          key={group.group_id} 
          group={group}
          editEnabled={organizationAdmin}
        />
			)
		});
	}

  const renderCreateGroup = () => {
    if(organizationAdmin) {
      return(
        <div className="col-12 col-md-6 mb-3">
          <button
            className="btn btn-outline-primary"
            onClick={handleCreateModal}
          >
            + Create Group
          </button>
        </div>
      )
    }
  }

	return (
		<div className="container-fluid p-3 mb-3 rounded-3 bg-white h-100">
			<div className="row mb-2">
        <div className="col-12">
          <h1 className="text-capitalize text-gradient mb-0 d-inline-block">
            Organization Groups
          </h1>
        </div>
      </div>

			<div className="row">
        <div className="col-12 col-md-6 mb-3">
          <input
            type="text"
            value={query}
            className="form-control"
            placeholder={'Search groups'}
            onChange={(e) => setQuery(e.target.value)}
          />
        </div>
        
        {renderCreateGroup()}
      </div>

      <CardList>
        {renderGroups()}
      </CardList>
		</div>
	);
};

export default OrganizationGroups;
