import React, { useContext, useEffect, useState } from "react";
import { SuperFetchContext } from "../../context/SuperFetchContext";
import useTranslations from "../../hooks/useTranslations";
import { ModalContext } from "../../context/ModalContext";
import { navigate } from "@reach/router";
import { TemplatesContext } from "../../context/TemplatesContext";
import { AvatarsContext } from "../../context/AvatarsContext";
import MessagesService from "../../services/MessagesService";
import { MessagesContext } from "../../context/MessagesContext";
import { getValue } from "../../utils";
import { AuthContext } from "../../context/AuthContext";

const SuperFetchSaveAvatar = () => {
  const [loading, setLoading] = useState(false);

  const { user } = useContext(AuthContext);
  const { clearModal } = useContext(ModalContext);
  const { feedback, getTemplatePrompt } = useContext(SuperFetchContext);
  const { setEnhancing, enhanced, setEnhanced } = useContext(MessagesContext);
  const { avatar, super_fetch_avatar, setAvatar, saveAvatar, saveAvatarFile, setPropertyAvatar } =
    useContext(AvatarsContext);

  
  useEffect(() => {
    const newAvatarData = {
      user_id: user.user_id,
      name: ''
    }

    setEnhanced(null);
    setAvatar(newAvatarData);

    return () => {
      setEnhanced(null);
    }
  }, []);

  useEffect(() => {
    if(
      enhanced !== null 
      && enhanced !== undefined
      && enhanced.length > 0
    ){
      handleSubmit(enhanced);
    }
  }, [enhanced]);


  const handleSubmit = async (context) => {
    if(avatar) avatar.context = context;

    const { avatar_id, assistant_id } = await saveAvatar(avatar);
    const currentFile = super_fetch_avatar?.file;
    
    setLoading(false);
    if(currentFile) saveAvatarFile(currentFile, assistant_id, avatar_id);
  };

  const handleEnhance = () => {
    if (avatar?.name === "") {
      return alert("You must add a name to your avatar.");
    }
    setLoading(true);
    setEnhancing(true);
    const content = getTemplatePrompt();

    MessagesService.postMessage({
      content: `
        Below I will give you some instructions and considerations so that you can improve the instruction fields taking into account each consideration that I give you: 
        
        Consiter the next to improve the Instrucions:
          -You will give me the same instrucions in the same format
          -You must not modify the content and structure, and you must not add more instructions or lines.
          -Every field begins by a "-", dont modify the fileds and not add more fields, the only thing you will improve is the instruction after each field.
          -Write the instruction to get the result, not the result itself. 
          -Consider the words within brackets will be changed to introduce user inputs in the instruction so dont modify it and use it where i can identify to insert the user inputs  

        Instructions: 
        ${content}
      `,
      enhance: true,
      stream: false,
    });

  };

  return(
    <div>
      <form className="container px-0">
        <label>Avatar Name</label>
        <input
          type="text"
          value={getValue(avatar, "name")}
          className="form-control mb-3"
          onChange={(e) => setPropertyAvatar("name", e.target.value)}
        />
        <label>Description</label>
        <input
          type="text"
          value={getValue(avatar, "description")}
          className="form-control mb-3"
          onChange={(e) => setPropertyAvatar("description", e.target.value)}
        />
        {/*<ImageInput />*/}
        <div className="row mt-3">
          <div className="col-6">
            <button
              type="button"
              onClick={handleEnhance}
              disabled={loading}
              className="btn btn-primary w-100"
            >
              {loading ? <div className="spinner-border"></div> : "Save"}
            </button>
          </div>
          <div className="col-6">
            <button
              type="button"
              onClick={clearModal}
              className="btn btn-link w-100 text-muted"
            >
              Cancel
            </button>
          </div>
        </div>
      </form>

    </div>

  )
};

export default SuperFetchSaveAvatar;
