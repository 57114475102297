import { useCallback, useContext, useEffect, useRef, useState } from "react";

import { createReactEditorJS } from "react-editor-js";
import { EDITOR_JS_TOOLS, parseBlock, htmlToBlocks } from "../utils/editor-tools";
import DocumentTools from "../components/common/DocumentTools";
import { SuperFetchContext } from "../context/SuperFetchContext";
import { DocumentsContext } from "../context/DocumentsContext";
import useWindowSize from "../hooks/useWindowSize";
import useFilesConverter from "../hooks/global/useFilesConverter";
import DocumentHeader from "../components/documents/DocumentHeader";
import { ModalContext } from "../context/ModalContext";

const ReactEditorJS = createReactEditorJS();
const defaultBlocks = [
  {
    id: "sheNwCUP5A",
    type: "header",
    data: {
      text: "Start writing here!",
      level: 2,
    },
  },
];

const SingleDocument = ({ documentId }) => {
  const [blocks, setBlocks] = useState([]);
  const [asideActive, setAsideActive] = useState(false);
  const [content, setContent] = useState("");
  const { export2Doc, export2pdf } = useFilesConverter();

  const { outputs } = useContext(SuperFetchContext);
  const { alert } = useContext(ModalContext);
  const {
    loadingOutputs,
    setLoadingOutputs,
    saveDocument,
    document: doc,
    getSingleDocument,
    setDocument,
    getDocumentConverted,
    documentBuffer,
  } = useContext(DocumentsContext);

  const editorCore = useRef(null);
  const smallDevice = useWindowSize(1200);

  useEffect(() => {
    getSingleDocument(documentId);
    return () => {
      setDocument(null);
    };
  }, []);

  useEffect(() => {
    if(!smallDevice){
      setAsideActive(true);
    } else {
      setAsideActive(false);
    }
  }, [smallDevice]);

  useEffect(() => {
    if (documentBuffer !== null) {
      export2pdf(documentBuffer);
    }
  }, [documentBuffer]);

  useEffect(() => {
    if (doc) {
      handleDocumentBlocks();
    }
  }, [doc]);

  const handleDocumentBlocks = () => {
    if (doc.content?.length > 0) {
      setTimeout(() => {
        const currentBlocks = htmlToBlocks(doc.content);
        handleRender(currentBlocks);
      }, 100);
    } else {
      setTimeout(() => {
        handleRender(defaultBlocks);
      }, 100);
    }
  };

  useEffect(() => {
    if (outputs && outputs !== null && outputs.length > 0) {
      if (loadingOutputs) {
        setLoadingOutputs(false);
        const newBlocks = [
          ...blocks,
          {
            id: `block-${blocks.length + 1}`,
            type: "paragraph",
            data: {
              text: outputs[0].content[0].text.value,
            },
          },
        ];
        handleRender(newBlocks);
      }
    }
  }, [outputs]);

  const toggleAside = () => {
    setAsideActive(!asideActive);
  };

  const handleBlocksToHTML = (blocks) => {
    let newContent = "";
    blocks.map((block) => {
      newContent += parseBlock(block);
    });
    setContent(newContent);
  };

  const handleSaveDocumentContent = () => {
    if(content.length < 65000){
      saveDocument({ ...doc, content });
    } else {
      alert('The file length is too large, please remove text to save')
    }
  };

  const createWord = () => {
    export2Doc("document");
  };

  const createPDF = () => {
    getDocumentConverted(documentId);
  };

  const handleInitialize = useCallback((instance) => {
    editorCore.current = instance;
  }, []);

  const handleSave = useCallback(async () => {
    const savedData = await editorCore.current.save();
    setBlocks(savedData.blocks);
    handleBlocksToHTML(savedData.blocks);
  }, []);

  const handleRender = useCallback(async (newBlocks) => {
    await editorCore.current.render({ blocks: newBlocks });
    await handleSave();
  }, []);

  const renderDocumentTools = () => {
    if (asideActive) {
      return <DocumentTools
        createPDF={createPDF}
        createWord={createWord}
        toggleAside={toggleAside}
        asideActive={asideActive}
        sideBarWidth={450}
      />
    }
  };

  return (
    <div className="container-fluid py-3 d-flex flex-column h-100 bg-white rounded-3">
      <div className="row position-relative" style={{ flex: 1, overflow: "hidden" }}>
        <div className="h-100" style={{ overflowY: "auto", flex: 1, padding: smallDevice ? '0' : '1rem' }}>
          <DocumentHeader
            doc={doc}
            handleSaveDocumentContent={handleSaveDocumentContent}
            smallDevice={smallDevice}
            toggleAside={toggleAside}
            asideActive={asideActive}
            title={doc?.name}
          />
          <ReactEditorJS
            onChange={handleSave}
            onInitialize={handleInitialize}
            defaultValue={{ blocks }}
            tools={EDITOR_JS_TOOLS}
          />
        </div>
        {renderDocumentTools()}
      </div>
    </div>
  );
};

export default SingleDocument;
