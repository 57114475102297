import Embed from '@editorjs/embed'
import Table from '@editorjs/table'
import Paragraph from '@editorjs/paragraph'
import List from '@editorjs/list'
// import Warning from '@editorjs/warning'
// import Code from '@editorjs/code'
import LinkTool from '@editorjs/link'
// import Image from '@editorjs/image'
// import Raw from '@editorjs/raw'
import Header from '@editorjs/header'
// import Quote from '@editorjs/quote'
import Marker from '@editorjs/marker'
import CheckList from '@editorjs/checklist'
import HTMLReactParser from 'html-react-parser'
// import Delimiter from '@editorjs/delimiter'
// import InlineCode from '@editorjs/inline-code'
// import SimpleImage from '@editorjs/simple-image'

export const EDITOR_JS_TOOLS = {
  // NOTE: Paragraph is default tool. Declare only when you want to change paragraph option.
  // paragraph: Paragraph,
  embed: Embed,
  table: Table,
  list: List,
  // warning: Warning,
  // code: Code,
  linkTool: LinkTool,
  image: Image,
  // raw: Raw,
  header: Header,
  // quote: Quote,
  marker: Marker,
  checklist: CheckList,
  // delimiter: Delimiter,
  // inlineCode: InlineCode,
  // simpleImage: SimpleImage,
}

const renderListItems = (items) =>
  items.map((item) => `<li>${item}</li>`).join("");

export const parseBlock = (block) => {
  if (block.type === "list") {
    if (block.data.style === "ordered") {
      return `<ol>${renderListItems(block.data.items)}</ol>`;
    } else {
      return `<ul>${renderListItems(block.data.items)}</ul>`;
    }
  }
  if (block.type === "quote") {
    return `<div ${
      block.data.alignment === "center" ? "className='text-center'" : ""
    }><quote>${block.data.text}</quote><p>${block.data.caption}</p></div>`;
  }
  if (block.type === "header") {
    return `<h${block.data.level}>${block.data.text}</h${block.data.level}>`;
  }
  if (block.type === "paragraph") {
    return `<p>${block.data.text}</p>`;
  }
};

export const htmlToBlocks = (html) => {
  const htmlBlocks = HTMLReactParser(html);
  if (!Array.isArray(htmlBlocks)) {
    return [{ type: "paragraph", data: { text: htmlBlocks } }];
  }
  const blocks = [];
  htmlBlocks.forEach((block) => {
    if (block.type === "p") {
      blocks.push({ type: "paragraph", data: { text: block.props.children } });
    } else if (block.type[0] === "h") {
      const level = block.type[1];
      blocks.push({
        type: "header",
        data: {
          text: block.props.children,
          level,
        },
      });
    } else if (block.type === "ol") {
      blocks.push({
        type: "list",
        data: {
          style: "ordered",
          items: block.props.children.map(({ props }) => {
            if (Array.isArray(props.children)) {
              return props.children[0];
            }
            return props.children;
          }),
        },
      });
    } else if (block.type === "ul" && Array.isArray(block.props.children)) {
      blocks.push({
        type: "list",
        data: {
          style: "unordered",
          items: block.props.children.map(({ props }) => props.children),
        },
      });
    }
  });
  return blocks;
};
