import React, { useContext, useEffect } from "react";
import { UserWorkflowsContext } from "../context/UserWorkflowsContext";
import UserWorkflowList from "../components/user_workflows/UserWorkflowList";
import useTranslations from "../hooks/useTranslations";

const UserWorkflows = () => {
  const translations = useTranslations();
  const { user_workflows, getUserWorkflows } = useContext(UserWorkflowsContext);

  useEffect(() => {
    getUserWorkflows();
  }, []);

  return (
    <div className="container-fluid bg-white p-4 card">
      <h1 className="mb-4">{translations.user_workflows.title}</h1>
      <UserWorkflowList user_workflows={user_workflows} />
    </div>
  );
};

export default UserWorkflows;
