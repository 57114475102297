import React, { useContext, useEffect, useState } from "react";
import useTranslations from "../../../hooks/useTranslations";
import { CampaignsContext } from "../../../context/CampaignsContext";
import { OrganizationsContext } from "../../../context/OrganizationsContext";
import useWindowSize from "../../../hooks/useWindowSize";
import PanelTitle from "../../../components/global/PanelTitle";
import OrganizationAdminTable from "../../../components/organization/organizations/OrganizationAdminTable";
import OrganizationTableRow from "../../../components/organization/organizations/OrganizationTableRow";
import CampaignAccessHandler from "../../../components/organization/campaign/CampaingAccessHandler";
import { useCampaignAccess } from "../../../hooks/campaigns/useCampaignsAccess";
import { GroupsContext } from "../../../context/GroupsContext";

const userTableCols = [
	{
		name: "#ID",
	},
	{
		name: "Name",
	},
	{
		name: "Email",
	},
	{
		name: "Role",
	},
	{
		name: "Actions",
	},
];

const groupTableCols = [
	{
		name: "#ID",
	},
	{
		name: "Name",
	},
	{
		name: "Actions",
	},
];

const CampaignUsers = ({ campaign_id }) => {
	const [currentUsers, setCurrentUsers] = useState([]);
	const [currentGroups, setCurrentGroups] = useState([]);

	const { campaign, getSingleCampaign } = useContext(CampaignsContext);
	const { getGroupsByOrganization, groups } = useContext(GroupsContext);
	const { organization } = useContext(OrganizationsContext);

	const { getGroupsData, getUsersData } = useCampaignAccess();
	const isSmallDevice = useWindowSize(1200);

	const { handleGiveCampaignAccess, handleRevokeCampaignAccess } =
		CampaignAccessHandler();

	const translation = useTranslations()

	useEffect(() => {
		if (organization) getViewData();
	}, [organization]);

	useEffect(() => {
		if (groups && organization.organization_id && campaign) {
			getCampaignAccess();
		}
	}, [groups, organization, campaign]);

	const getCampaignAccess = () => {
		const groupsIds = campaign.campaign_groups.map((obj) => {
			return obj.group_id;
		});

		const usersIds = campaign.campaign_users.map((obj) => {
			return obj.user_id;
		});

		const groups = getGroupsData(groupsIds);
		const users = getUsersData(usersIds);

		setCurrentGroups(groups);
		setCurrentUsers(users);
	};

	const handleGiveAccess = (type) => {
		if (campaign_id) {
			handleGiveCampaignAccess(campaign_id, type);
		}
	};

	const handleRevokeAccess = ({ group_id, user_id }) => {
		if (campaign_id) {
			handleRevokeCampaignAccess({ campaign_id, group_id, user_id });
		}
	};

	const getViewData = () => {
		getSingleCampaign(campaign_id);
		getGroupsByOrganization(organization.organization_id);
	};

	const renderCampaignUsers = () => {
		return currentUsers?.map((obj) => {
			return (
				<OrganizationTableRow
					key={obj.user_id}
					user={obj.user}
					role={obj.user_type.name}
					link={`users/${obj.user?.user_id}`}
					onDelete={handleRevokeAccess}
					deleteField
				/>
			);
		});
	};

	const renderCampaignGroups = () => {
		return currentGroups?.map((obj) => {
			return (
				<tr
					key={obj.group_id}
					className="p-2 border-bottom small align-middle hover-light"
				>
					<td className="td-id">{obj.organization_id}</td>
					<td className="td-id">{obj.name}</td>
					<td className="td-id text-end">
						<button
							className="btn small hover-light text-danger p-0 m-0"
							onClick={() => handleRevokeAccess({ group_id: obj.group_id })}
						>
							{translation.campaigns.revokeAccess.split(" ")[0]}
							<i className="fa fa-ban ms-1"></i>
						</button>
					</td>
				</tr>
			);
		});
	};

	return (
		<div
			className="container-fluid d-flex flex-col bg-white 
				card position-relative h-100"
			style={{ padding: "1rem" }}
		>
			<div className="row">
				<div className="col px-0">
					<h2 className="mb-0 text-capitalize text-gradient d-inline-block">
						Campaign Users
					</h2>
				</div>
				<div className="col px-0 text-end">
					<p className="bold"> <i className="fas fa-user me-1"></i> {`${campaign?.user.name} ${campaign?.user.last_name}`}</p>
				</div>
			</div>

			<div
				className="container-fluid px-0 mt-3"
				style={{ flex: 1, overflowY: "auto" }}
			>
				<PanelTitle
					title={"Users"}
					onClick={() => handleGiveAccess("users")}
					smallTitle
				/>

				<OrganizationAdminTable tableColumns={userTableCols}>
					{renderCampaignUsers()}
				</OrganizationAdminTable>
			</div>

			<div
				className="container-fluid px-0 mt-3"
				style={{ flex: 1, overflowY: "auto" }}
			>
				<PanelTitle
					title={"Groups"}
					onClick={() => handleGiveAccess("groups")}
					smallTitle
				/>

				<OrganizationAdminTable tableColumns={groupTableCols}>
					{renderCampaignGroups()}
				</OrganizationAdminTable>
			</div>
		</div>
	);
};

export default CampaignUsers;
