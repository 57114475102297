import React, { useContext, useEffect } from "react";
import { ModalContext } from "../context/ModalContext";
import { UserWorkflowsContext } from "../context/UserWorkflowsContext";
import UserWorkflowFieldForm from "../components/user_workflows/UserWorkflowFieldForm";
import UserWorkflowTemplateCard from "../components/user_workflows/UserWorkflowTemplateCard";
import ConfirmDeleteUserWorkflow from "../components/user_workflows/ConfirmDeleteUserWorkflow";

const SingleUserWorkflow = ({ user_workflow_id }) => {
  const { clearModal, modalComponent } = useContext(ModalContext);
  const { user_workflow, getSingleUserWorkflow } =
    useContext(UserWorkflowsContext);

  useEffect(() => {
    getSingleUserWorkflow(user_workflow_id);
  }, [user_workflow_id]);

  const confirmDelete = () => {
    modalComponent(
      "Delete Workflow",
      <ConfirmDeleteUserWorkflow user_workflow_id={user_workflow_id} />
    );
  };

  const handleFieldValues = () => {
    modalComponent(
      "Field Values",
      <UserWorkflowFieldForm handleCancel={clearModal} />
    );
  };

  const renderTitle = () => {
    if (user_workflow && user_workflow !== null) {
      return user_workflow.name;
    }
  };

  const renderWorkflow = () => {
    if (user_workflow && user_workflow !== null) {
      const { workflow } = user_workflow;
      if (workflow && workflow !== null) {
        return (
          <div className="my-4">
            <h2>{workflow.name}</h2>
            <p>{workflow.description}</p>
          </div>
        );
      }
    }
  };

  const renderTemplates = () => {
    if (user_workflow && user_workflow !== null) {
      const { user_workflow_templates } = user_workflow;
      if (Array.isArray(user_workflow_templates)) {
        return (
          <div className="row">
            {user_workflow_templates.map(({ conversation_id, template }) => (
              <div key={template.template_id} className="col-12 col-md-6">
                <UserWorkflowTemplateCard
                  user_workflow_id={user_workflow.user_workflow_id}
                  conversation_id={conversation_id}
                  template={template}
                />
              </div>
            ))}
          </div>
        );
      }
    }
  };

  return (
    <div className="container-fluid bg-white card p-4">
      <div className="row">
        <div className="col-8">
          <h1>{renderTitle()}</h1>
        </div>
        <div className="col-4 text-end">
          <button
            onClick={confirmDelete}
            className="btn btn-outline-danger me-2"
          >
            <i className="fa fa-trash"></i>
          </button>
          <button onClick={handleFieldValues} className="btn btn-primary">
            <i className="fa fa-database"></i>
          </button>
        </div>
      </div>
      {renderWorkflow()}

      <h2 className="my-3">Templates</h2>
      {renderTemplates()}
    </div>
  );
};

export default SingleUserWorkflow;
