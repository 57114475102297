import React, { useContext, useState } from "react";

const SuperFetchOutput = ({ text }) => {

  return(
    <div className="card bg-accent-light p-3 mb-2">
      <pre 
        className="mb-0"
        style={{whiteSpace: 'pre-wrap'}} 
      >
        {`${text}`}
      </pre>
    </div>
 
  )
};

export default SuperFetchOutput;
