import React, { useContext, useEffect, useRef, useState } from "react";
import { AvatarsContext } from "../../context/AvatarsContext";
import AvatarInputMessage from "./AvatarInputMessage";
import AvatarMessageCard from "./AvatarMessageCard";
import useTranslations from "../../hooks/useTranslations";
import { MessagesContext } from "../../context/MessagesContext";

const AvatarOutput = ({ 
  templatesActive,
  setTemplatesActive,
  disableTemplatesBtn
 }) => {
  const [inputValue, setInputValue] = useState('');

  const { avatar } = useContext(AvatarsContext);
  const { 
    saveMessage, 
    getThreadMessages, 
    threadMessages,
    runingThread
  } = useContext(MessagesContext);

  const translations = useTranslations();
  const messagesContainer = useRef(null);

  useEffect(() => {
    if(avatar?.assistant_id) {
      getThreadMessages(avatar?.assistant_id);
    }
  }, [avatar]);

  useEffect(() => {
    handleScrollBottom();
  }, [threadMessages]);

  const handleScrollBottom = () => {
    if (messagesContainer.current) {
      
      messagesContainer.current.scrollTo({
        top: messagesContainer.current.scrollHeight,
        behavior: 'smooth',
      });
    }
  }

  const handleSubmitMessage = async (event) => {
    event.preventDefault();

    const messageData = {
      avatarMessage: true,
      content: inputValue,
      assistant_id: avatar.assistant_id
    }

    if(inputValue.length > 0) {
      setInputValue('');
      saveMessage(messageData);
    }

  }

  const renderSpinner = () => {
    if (runingThread) {
      return (
        <div className="loading mb-3">
          <span>Hold tight, {avatar?.name} is working on your instruction</span>
        </div>
      );
    }
  };

  const renderMessages = () => {
    if(threadMessages?.length > 0) {
      return threadMessages.map((message, index) => {
        let lastMessage = false;
        if(index === threadMessages.length - 1) lastMessage = true;

        return(
          <AvatarMessageCard 
            key={message.message_id} 
            message={message} 
            lastMessage={lastMessage}
          />
        )
      });
    }
 
  }

  return (
    <div className="card bg-light h-100 position-relative" style={{overflowY: 'hidden'}}>

      <div className="card-header">
        <h3>{avatar?.name} - Avatar</h3>
      </div>

      <div 
        className="card-body position-relative pe-0 pt-0 w-100" 
        style={{height: 'calc(100% - 50px)'}}
      >
        
        <div 
          className="row pe-3 pt-2 flex-column justify-content-start flex-nowrap"
          style={{
            height: 'calc(100% - 115px)', 
            overflowY: 'auto', 
            overflowX: 'hidden'
          }}
          ref={messagesContainer}
        >
          <div className="col-12 d-flex mt-1 justify-content-center" style={{height: 'max-content'}}>
            <span className="border bg-accent badge badge-pill mb-3 mx-auto ">
              {translations.conversation.max_messages}
            </span>
          </div>

          {renderMessages()}
          {renderSpinner()}
        </div>

        <AvatarInputMessage
          disableTemplatesBtn={disableTemplatesBtn}
          spinner={runingThread}
          value={inputValue}
          setValue={setInputValue}
          handleSubmit={handleSubmitMessage}
          setTemplatesActive={setTemplatesActive}
          templatesActive={templatesActive}
        />
      </div>
    </div>
  );
};

export default AvatarOutput;
