import React, { useContext, useEffect } from "react";
import Generator from "./Generator";
import { MenuContext } from "../context/MenuContext";
import useTranslations from "../hooks/useTranslations";

const HashtagGenerator = () => {
  const translations = useTranslations();
  const { setSelected } = useContext(MenuContext);

  useEffect(() => {
    setSelected("Hashtag Generator");
  }, []);

  return (
    <Generator
      base_prompt="Generate 10 to 20 trending hashtags ideas for "
      title={translations.hashtags.title}
      message_type_id={2}
    />
  );
};

export default HashtagGenerator;
