import { useContext } from "react";
import { SocketContext } from "../context/SocketContext";
import { AuthContext } from "../context/AuthContext";


export default function useEmitSocket(){
  const { socket } = useContext(SocketContext);
  const { user } = useContext(AuthContext);
  const room = `user-${user.user_id}`;

  const cancelChatStream = () => {
    console.log('cancel');
    
    socket.emit('cancelChatStream');
  }

  return {
    cancelChatStream
  }

}