import React from "react";

const AvatarTraining = ({
  avatarName,
  handleBegin
}) => {
  

  return(
    <div 
      className="card bg-light p-3 pt-0 d-flex flex-column 
      align-items-center justify-content-around" 
      style={{height: '200px'}}
    >
      <div className="card-header py-0">
        <h3 className="fs-3 mb-0 text-primary text-gradient ">Train your Avatar!</h3>
      </div>

      <h3>
        Your Avatar <span className="text-accent fs-4 mx-2"><b>{avatarName}</b></span> doesn't have any context yet.
      </h3>

      <button
        className="btn btn-primary"
        onClick={handleBegin}
      >
        Begin to training it!
      </button>
    </div>
  )
}

export default AvatarTraining;