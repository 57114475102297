import React, { useRef, useEffect, useContext } from "react";
import { ModalContext } from "../../context/ModalContext";
import MessagesService from "../../services/MessagesService";
import { MessagesContext } from "../../context/MessagesContext";
import { setupTooltips } from "../../utils";
import { TemplatesContext } from "../../context/TemplatesContext";
import useTranslations from "../../hooks/useTranslations";
import { ConversationsContext } from "../../context/ConversationsContext";
import { OrganizationsContext } from "../../context/OrganizationsContext";
import useWindowSize from "../../hooks/useWindowSize";

const MessageInputForm = ({
  spinner,
  handleSubmit,
  disableTemplatesBtn,
  addGenerateBtn,
  handleGenerateBtn,
}) => {
  const textArea = useRef(null);
  const translations = useTranslations();
  const { setTemplate } = useContext(TemplatesContext);
  const { clearModal } = useContext(ModalContext);
  const { conversation, setPropertyConversation } =
    useContext(ConversationsContext);

  const { prompt, enhanced, enhancing, setPrompt, setEnhanced, setEnhancing } =
    useContext(MessagesContext);

  const { organization } = useContext(OrganizationsContext);

  useEffect(() => {
    setupTooltips();
    if (prompt && prompt !== null) {
      setTemplate(null);
    }
    return () => {
      setPrompt("");
    };
  }, []);

  useEffect(() => {
    const setupHeight = () => {
      if (prompt === "") {
        textArea.current.style.height = "0px";
      } else {
        textArea.current.style.height = textArea.current.scrollHeight + "px";
      }
    };

    setupHeight();
  }, [textArea, prompt]);

  useEffect(() => {
    if (enhanced && enhanced !== null) {
      setEnhancing(false);
      setPrompt(enhanced);
    }
  }, [enhanced]);

  const handleApplyTemplate = (currentPrompt) => {
    setPrompt(currentPrompt);
    clearModal();
  };

  const handleEnhance = () => {
    setEnhancing(true);

    const messageData = {
      content: `Transform this lackluster prompt into a more effective and engaging question or statement: "${prompt}". Write the instruction to get the result, not the result itself.`,
      enhance: true,
      stream: false,
    }

    if (
      organization.organization_id
      && organization.organization_id !== undefined
    ) {
      messageData.organization_id = organization.organization_id;
    }

    MessagesService.postMessage(messageData);
  };

  const handleTemplates = () => {
    if (conversation?.showTemplates) {
      setPropertyConversation("showTemplates", false);
    } else {
      setPropertyConversation("showTemplates", true);
    }
  };

  const isSmallDevice = useWindowSize(1200)

  return (
    <div id="message-input" className="card w-100">
      <form
        className="row" style={{ padding: isSmallDevice ? '1rem .5rem' : "1rem" }}
        onSubmit={(e) => {
          e.preventDefault();
          setEnhancing(false);
          handleSubmit(prompt);
          setEnhanced(null);
          setPrompt("");
        }}
      >
        <div className="mb-3 px-0">
          <textarea
            type="text"
            ref={textArea}
            value={prompt}
            className="form-control"
            placeholder={translations.conversation.input.placeholder}
            onChange={(e) => setPrompt(e.target.value)}
          />
        </div>
        <div className="row px-0">
          <div className="col-12 col-md-6 px-0 d-flex align-items-center">
            {String(prompt).length > 12 && (
              <button
                type="button"
                onClick={handleEnhance}
                disabled={enhancing}
                className="btn btn-sm btn-outline-primary me-2"
              >
                {enhancing ? (
                  <div className="spinner-border small"></div>
                ) : (
                  <span>
                    <i className="fas fa-magic me-2"></i>
                    {translations.conversation.input.enhance}
                  </span>
                )}
              </button>
            )}
            <button
              type="button"
              disabled={enhancing || disableTemplatesBtn}
              onClick={handleTemplates}
              className={`${disableTemplatesBtn ? "d-none" : ""} btn btn-sm btn-outline-primary me-2`}
            >
              <span>
                <i className="fas fa-shapes me-2"></i>
                {translations.conversation.input.templates.button}
              </span>
            </button>
            <button
              type="button"
              onClick={handleGenerateBtn}
              className={`${addGenerateBtn ? "" : "d-none"} btn btn-sm
            btn-accent me-2`}
            >
              <i className="fa fa-arrow-left me-2"></i>
              Back
            </button>
          </div>
          <div className="col-12 col-md-6 px-0">
            <button
              type="submit"
              disabled={spinner || enhancing || String(prompt).length < 10}
              className="btn btn-primary w-100 mt-2"
            >
              {spinner ? (
                <div className="spinner-border"></div>
              ) : (
                <span>
                  <i className="fa fa-paper-plane me-2"></i>
                  {translations.conversation.input.send}
                </span>
              )}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default MessageInputForm;
