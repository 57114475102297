import React, { useContext, useEffect } from "react";
import useTranslations from "../../hooks/useTranslations";
import { AuthContext } from "../../context/AuthContext";

const ToggleLanguage = () => {
  const { user, setPropertyUser } = useContext(AuthContext);
  const { lang, setLang } = useTranslations();

  const handleLangChange = (e) => {
    const selectedLang = e.target.value;
    if (user && user?.lang !== selectedLang) {
      setPropertyUser("lang", selectedLang)
    }
    setLang(selectedLang);
  };

  useEffect(() => {
    if (user?.lang != null) {
      setLang(user.lang);
    } else {
      setLang('en');
    }
  }, [])

  return (
    <select
      value={lang}
      onChange={handleLangChange}
      className="form-control text-center"
    >
      <option value="en">EN - English</option>
      <option value="es">ES - Español</option>
    </select>
  );
};

export default ToggleLanguage;
