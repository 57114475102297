import React, { useState, useContext, useEffect } from "react";
import { AuthContext } from "../context/AuthContext";
import { MenuContext } from "../context/MenuContext";
import useTranslations from "../hooks/useTranslations";
import { ConversationsContext } from "../context/ConversationsContext";
import ConversationList from "../components/conversations/ConversationList";
import ConversationUpgrade from "../components/conversations/ConversationUpgrade";
import ConversationTagCard from "../components/conversations/ConversationTagCard";
import ConversationsActions from "../components/conversations/ConversationsActions";
import { OrganizationsContext } from "../context/OrganizationsContext";
import useWindowSize from "../hooks/useWindowSize";

const Conversations = ({ tag }) => {
  const [query, setQuery] = useState("");
  const [sortBy, setSortBy] = useState("");
  const [viewArchived, setViewArchived] = useState(false);
  const { setSelected } = useContext(MenuContext);
  const { organization } = useContext(OrganizationsContext);
  const [filter, setFilter] = useState("");

  const translations = useTranslations();
  const { user } = useContext(AuthContext);
  const { getConversations, getAllTags, tags, conversation } =
    useContext(ConversationsContext);

  const isSmallDevice = useWindowSize(1200);

  const isTagsPath = window.location.pathname.includes("/conversation/tags/");
  const isNotRootTagsPath = window.location.pathname !== "/conversation/tags";



  useEffect(() => {
    if (isTagsPath && tag) {
      if (!organization) {
        return
      }

      getConversations({ query, sortBy, archived: viewArchived, tags: tag, organization_id: organization.organization_id });
    } else if (!isTagsPath) {
      getConversations({ query, sortBy, archived: viewArchived, organization_id: organization.organization_id });
      getAllTags({ query });
      setSelected("Fetch");
    }
  }, [query, viewArchived, tag, organization, sortBy]);


  useEffect(() => {
    getAllTags({ query });
    if(conversation?.archived === false) {
      setViewArchived(false);
    }
  }, [conversation]);

  const renderConversations = () => {
    if (!user.has_access) {
      return <ConversationUpgrade />;
    }

    if (isTagsPath && isNotRootTagsPath) {
      return <ConversationList />;
    }

    if (Array.isArray(tags) && filter === "tags") {
      return tags.map((tag) => <ConversationTagCard key={tag} tag={tag} />);
    }

    return <ConversationList />;
  };

  return (
    <div className="container-fluid bg-white card position-relative h-100" style={{ padding: !isSmallDevice ? "1.5rem" : "1rem" }}>
      <div className="col-12 mb-2 col-md-4 ps-0">
        <h1 className="mb-0 h2 text-capitalize text-gradient d-inline-block">
          {translations.conversations.title}
        </h1>
      </div>
      <div className="row">
        <div className="col-12 col-md-6 px-0 pe-md-2">
          <input
            type="text"
            value={query}
            className="form-control mt-2"
            placeholder={translations.conversations.search}
            onChange={(e) => setQuery(e.target.value)}
          />
        </div>
        <div className="d-flex justify-content-end col-12 col-md-6 mt-2 px-0">
          <ConversationsActions
            filter={filter}
            sortBy={sortBy}
            setSortBy={setSortBy}
            setFilter={setFilter}
            viewArchived={viewArchived}
            setViewArchived={setViewArchived}
            isTagsPath={isTagsPath}
          />
        </div>
      </div>
      <div className="mt-3" style={{overflowY: 'auto'}}>
        {renderConversations()}
      </div>
    </div>
  );
};

export default Conversations;
