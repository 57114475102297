import React, { useState, useContext, useEffect, useRef } from "react";
import { MessagesContext } from "../../context/MessagesContext";
import { ModalContext } from "../../context/ModalContext";
import useTranslations from "../../hooks/useTranslations";
import MessageOutputForm from "./MessageOutputForm";
import MarkdownPreview from "@uiw/react-markdown-preview";
import ShareMessageForm from "./ShareMessage/ShareMessageForm";

const MessageCard = ({ message, prevMessage, handleCallback }) => {
  const { success, alert, clearModal, modalComponent } =
    useContext(ModalContext);
  const { saveMessage } = useContext(MessagesContext);

  const [editedText, setEditedText] = useState("");
  const [editMode, setEditMode] = useState(false);
  const [copied, setCopied] = useState(false);
  const contentRef = useRef(null);

  const translations = useTranslations();

  const { formatDateTime } = translations;

  useEffect(() => {
    if (copied) {
      success(translations.general.clipboard);
    }
  }, [copied]);

  // const handleSave = () => {
  //   modalComponent(
  //     translations.conversation.conversation.message.save,
  //     <MessageOutputForm
  //       message={message}
  //       prevMessage={prevMessage}
  //       handleCancel={clearModal}
  //     />
  //   );
  // };

  const handleCopy = () => {
    const content = contentRef.current;
    if (content) {
      content.style.backgroundColor = "white";
      const selection = window.getSelection();
      const range = document.createRange();
      range.selectNodeContents(content);
      selection.removeAllRanges();
      selection.addRange(range);

      try {
        document.execCommand("copy");
      } catch (e) {
        alert("Unable to copy content to clipboard. Please copy manually.");
      } finally {
        selection.removeAllRanges();
        setCopied(true);
        content.style.backgroundColor = "inherit";
      }
    }
  };

  const clearEdit = () => {
    setEditMode(false);
    setEditedText("");
  };

  const handleEdit = () => {
    if (editMode) {
      clearEdit();
      return saveMessage({ ...message, content: editedText }, handleCallback);
    }
    setEditMode(true);
    setEditedText(message.content);
  };

  const handleShare = () => {
    modalComponent("Share Content", <ShareMessageForm message={message} handleCancel={clearModal} />)
  };

  const renderMessage = () => {
    if (editMode) {
      return (
        <textarea
          rows="10"
          type="text"
          value={editedText}
          className="form-control mb-3"
          onChange={(e) => setEditedText(e.target.value)}
        />
      );
    }
    if (message.content === null) {
      return translations.conversation.message.pending;
    }
    let { content } = message;
    content = String(message.content);
    if (content !== undefined && content !== null && content !== "undefined") {
      return <MarkdownPreview source={content} />;
    }
  };

  return (
    <div
      className={`card p-3 mb-3 message-card ${message.role === "assistant"
        ? "ms-0 me-auto bg-accent-light"
        : "ms-auto me-0 bg-white"
        }`}
    >
      <div data-color-mode="light" ref={contentRef}>
        {renderMessage()}
      </div>
      <div className="row align-items-center mt-3">
        <div className="col-12 col-md-6 align-items-center">
          <p className="small mb-0 text-muted">
            {formatDateTime(message.createdAt)}
          </p>
        </div>
        <div className="col-12 col-md-6 text-end">
          {message.role !== "user" && (
            <div>
              {!editMode && <button
                onClick={handleShare}
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title={translations.conversation.message.share}
                className="btn border-0 btn-round btn-sm btn-outline-dark me-1"
              >
                <i className="fa fa-share"></i>
              </button>

              }
              {editMode && (
                <button
                  onClick={clearEdit}
                  className="btn border-0 btn-round btn-sm btn-outline-danger me-1"
                >
                  <i className="fa fa-times"></i>
                </button>
              )}
              <button
                onClick={handleEdit}
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title={translations.conversation.message.edit}
                className="btn border-0 btn-round btn-sm btn-outline-dark me-1"
              >
                <i className={`fa fa-${editMode ? "save" : "edit"}`}></i>
              </button>
              {!editMode && [
                <button
                  key="copy"
                  onClick={handleCopy}
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  title={translations.conversation.message.copy}
                  className="btn border-0 btn-round btn-sm btn-outline-dark me-1"
                >
                  <i className="fa fa-copy"></i>
                </button>,
              ]}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default MessageCard;
