import React, { useContext, useEffect, useState } from "react";
import TemplateCard from "./TemplateCard";
import { ModalContext } from "../../context/ModalContext";
import { TemplatesContext } from "../../context/TemplatesContext";
import TemplateInfo from "./TemplateInfo";
import { setupTooltips } from "../../utils";
import TemplateTagCard from "./TemplateTagCard";
import TemplateTypeCard from "./TemplateTypeCard";
import { navigate } from "@reach/router";
import useTranslations from "../../hooks/useTranslations";
import { ConversationsContext } from "../../context/ConversationsContext";
import { TranslationsContext } from "../../context/TranslationsContext";
import Pagination from "../global/Pagination";
import { OrganizationsContext } from "../../context/OrganizationsContext";
import { AuthContext } from "../../context/AuthContext";


const TemplateList = ({
  tag,
  defaultSelected,
  type,
  size,
  title,
  hideEdit,
  avatar_id,
  hideCreate,
  handleApply,
  fetchTemplates,
  disableAddBtn,
  filtersActive,
  titleGradient,
  backBtn,
  handleBackBtn,
}) => {
  const [page, setPage] = useState(1);
  const [query, setQuery] = useState("");

  const [sortBy, setSortBy] = useState("");

  const [showFavorites, setShowFavorites] = useState(false);
  const [currentTag, setCurrentTag] = useState(null);
  const [currentType, setCurrentType] = useState(null);
  
  const translations = useTranslations();
  const { clearModal, modalComponent } = useContext(ModalContext);
  const { user } = useContext(AuthContext);
  const { getConversations } = useContext(ConversationsContext);
  const { organization } = useContext(OrganizationsContext)
  const {
    tags,
    total,
    types,
    spinner,
    templates,
    getAllTags,
    getAllTypes,
    clearTemplates,
    templates_tab,
    setTemplatesTab,
    setTemplates
  } = useContext(TemplatesContext);
  const { lang } = useContext(TranslationsContext);

  useEffect(() => {
    getConversations();
    handleCallback();

    if (tag && tag != null) {
      setCurrentTag(tag)
    }

    if (type && type != null) {
      setCurrentType(type)
    }

  }, []);

  useEffect(() => {
    if (!spinner && user) {
      handleCallback();
    }
  }, [page, query, templates_tab, showFavorites, sortBy, user, organization, lang, currentTag, currentType]);

  useEffect(() => {
    handleCallback();
    if(templates_tab.length > 0) {
      setTemplates([]);
    }
  }, [templates_tab]);

  const handleSetType = (type) => {
    setCurrentTag(null);
    setCurrentType(type);
    setTemplatesTab("");
  }

  const handleSetTag = (tag) => {
    setCurrentType(null)
    setCurrentTag(tag);
    setTemplatesTab("");
  }


  useEffect(() => {
    setupTooltips();
  }, [templates]);

  useEffect(() => {
    setPage(1);

    if(templates_tab === '') {
      setSortBy('');
    }
  }, [templates_tab]);

  const getSortBy = () => {
    if (sortBy.includes("tag") && templates_tab !== "tags") {
      let current = `${templates_tab}_${String(sortBy.split("_")[1])}`;
      setSortBy(current);
      return current;
    } else if (sortBy.includes("type") && templates_tab !== "type") {
      let current = `${templates_tab}_${String(sortBy.split("_")[1])}`;
      setSortBy(current);
      return current;
    }
    return sortBy;
  };

  const handleCallback = () => {
    if (!spinner) {
      const current = getSortBy();

      if (templates_tab === "tags") {
        getAllTags({ query, page, language: user.lang, sortBy: current });
      }

      if (templates_tab === "type") {
        getAllTypes({ query, page, language: user.lang, sortBy: current });
      }

      if (templates_tab === "") {
        fetchTemplates({
          tag: currentTag,
          type: currentType,
          page,
          query,
          sortBy,
          favorites: showFavorites,
          language: user.lang,
        });
      }
    }
  };

  const handleInfo = () => {
    if (size !== "lg") {
      clearModal();
      setTimeout(() => {
        modalComponent("About Templates", <TemplateInfo />);
      }, 500);
    } else {
      modalComponent("About Templates", <TemplateInfo />);
    }
  };

  const handleCreateTemplate = () => {
    navigate("/templates/create-template");
  };

  const handleBack = () => {
    if(currentType){
      setTemplatesTab('type');
      setCurrentType(null);
    } else {
      setTemplatesTab('tags');
      setCurrentTag(null);
    }
  }


  const renderButtons = () => {
    if (currentType || currentTag) {
      return (
        <button
          onClick={handleBack}
          className="btn btn-outline-primary ms-3"
        >
          <i className="fa fa-chevron-left me-2"></i>{" "}
          {translations.templates.back}
        </button>
      );
    } else {
      return (
        <div
          className="btn-group ms-2 d-inline-block border br-25 mt-2"
          role="group"
          style={{
            minWidth: 180
          }}
        >
          <button
            type="button"
            onClick={() => setTemplatesTab("type")}
            className={`btn btn-sm ${templates_tab === "type" ? "btn-primary" : ""}`}
          >
            {translations.templates.type}
          </button>
          <button
            type="button"
            onClick={() => setTemplatesTab("tags")}
            className={`btn btn-sm ${templates_tab === "tags" ? "btn-primary" : ""}`}
          >
            {translations.templates.tag}
          </button>
          <button
            type="button"
            onClick={() => setTemplatesTab("")}
            className={`btn btn-sm ${templates_tab === "" ? "btn-primary" : ""}`}
          >
            {translations.templates.all}
          </button>
        </div>
      );
    }
  };

  const renderTemplates = () => {
    if (Array.isArray(templates) && templates_tab === "") {
      if (templates.length === 0) {
        return <p>{translations.templates.empty}</p>;
      }

      return templates.map((template) => (
        <div
          key={template.template_id}
          className={`col-12 ps-0 ${size === "lg" ? "col-xxl-4 col-xl-6" : ""} `}
          style={{
            height: "max-content",
          }}
        >
          <TemplateCard
            handleCallback={handleCallback}
            handleApply={handleApply}
            avatar_id={avatar_id}
            hideEdit={hideEdit}
            template={template}
            size={size}
          />
        </div>
      ));
    }
    if (Array.isArray(tags) && templates_tab === "tags") {
      return tags?.map((tag) => (
        <TemplateTagCard key={tag} tag={tag} size={size} setCurrentTag={handleSetTag} />
      ));
    }
    if (Array.isArray(types) && templates_tab === "type") {
      return types.map(({ type }) => (
        <TemplateTypeCard key={type} type={type} size={size} setCurrentType={handleSetType} />
      ));
    }
    if (spinner) return <div className="spinner-border"></div>;
  };

  const renderEmptyState = () => {
    if (templates?.length <= 0 && templates_tab === "") {
      return <p>There aren't any templates</p>
    }

    if (tags?.length <= 0 && templates_tab === "tags"){
      return <p>There aren't any Tags</p>
    }

    if (types?.length <= 0 && templates_tab === "type") {
      return <p>There aren't any Types</p>
    }
  }

  const renderAddBtn = () => {
    if (!disableAddBtn) {
      return (
        <div>
          {!hideCreate && (
            <button
              className="btn btn-outline-primary w-100"
              onClick={handleCreateTemplate}
            >
              <i className="fa fa-plus me-2"></i>
              {translations.templates.create}
            </button>
          )}
        </div>
      );
    }
  };

  const renderPagination = () => {
    if (total && total !== null) {
      return (
        <div className="py-3">
          <Pagination
            size={24}
            itemsPerPage={24}
            totalItems={total}
            currentPage={page}
            onPageChange={setPage}
          />
        </div>
      );
    }
  };

  return (
    <div
      id="templates"
      className="overflow-hidden position-relative
       h-100 d-flex flex-column flex-nowrap "
    >
      <section className="" style={{ height: "max-content" }}>
        <div className="row align-items-center">
          <div className="col-12 col-md-5 ps-0">
            <div>
              <button
                className={`btn btn-sm btn-accent mb-1 ${backBtn ? "" : "d-none"
                  }`}
                type="button"
                onClick={handleBackBtn}
              >
                <i className="fa fa-arrow-left me-2" />
                Conversation
              </button>
            </div>

            {size === "lg" ? (
              <h1
                className={`mb-0 text-capitalize ${titleGradient ? "text-gradient" : ""
                  }  d-inline-block`}
              >
                {title}
              </h1>
            ) : (
              <h1
                className={`mb-0 h3 bold ${titleGradient ? "text-gradient" : ""
                  } d-inline-block`}
              >
                {title}
              </h1>
            )}
          </div>

          <div className="col-12 col-md-7 pe-0 text-end">
            <button
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title={translations.templates.favorites}
              className={`btn btn-round btn-sm mt-2 ${
                showFavorites > 0 ? "btn-warning text-dark" : "border"
              } me-2`}
              onClick={() => setShowFavorites(!showFavorites)}
            >
              <i className="fa fa-star"></i>
            </button>

            <button
              onClick={handleInfo}
              className="btn btn-round border btn-sm mt-2"
            >
              <i className="fa fa-info-circle"></i>
            </button>
            {renderButtons()}
          </div>
        </div>

        <div className="row align-items-center my-3">
          <div className={` col-12 mb-2 mb-md-0 px-0 pe-md-2 col-md-${size === "lg" ? "7" : "12"}`}>
            <input
              type="text"
              value={query}
              className="form-control "
              placeholder={translations.templates.search}
              onChange={(e) => setQuery(e.target.value)}
            />
          </div>
          
          <div
            className={`col-12 px-0 col-md-${size === "lg" ? "5" : "12"}`}
          >
            <div className={`row ${disableAddBtn ? 'mt-2' : ''}`}>
              <div className="col-6 text-end px-0 mb-2 mb-md-0">
                <select
                  value={sortBy}
                  onChange={(e) => setSortBy(e.target.value)}
                  className="form-control d-inline-block"
                >
                  <option value="createdAt_desc">Last Created</option>
                  <option value="updatedAt_desc">Last Used</option>
                  <option value={`${templates_tab === "" ? "name" : templates_tab}_asc`}>
                    A-Z
                  </option>
                  <option value={`${templates_tab === "" ? "name" : templates_tab}_desc`}>
                    Z-A
                  </option>
                </select>
              </div>
              <div className="col-6 pe-0">{renderAddBtn()}</div>
            </div>
          </div>
        </div>
      </section>

      <div
        className={`row align-items-start justify-content-start`}
        style={{ overflowY: "auto" }}
      >
        {renderEmptyState()}
        {renderTemplates()}
      </div>
      {renderPagination()}
    </div>
  );
};

export default TemplateList;
