import React, { useContext, useEffect, useState } from "react";
import { useLocation } from "@reach/router";
import TemplateForm from "../components/templates/TemplateForm";
import { navigate } from "@reach/router";
import { TemplatesContext } from "../context/TemplatesContext";

const SingleTemplate = ({ title, idTemplate }) => {
  const location = useLocation();

  const { setTemplate, getSingleTemplate } = useContext(TemplatesContext);

  useEffect(() => {
    if (idTemplate) getViewData();
  }, []);

  const getViewData = () => {
    getSingleTemplate(idTemplate);
  };

  const onCancel = () => {
    navigate("/templates");
  };

  const onCreateTemplate = () => {
    setTemplate(null);
    navigate("/templates");
  };

  return (
    <div
      className="px-4 py-4 card bg-white"
      style={{
        height: "100%",
      }}
    >
      <div className="col-12 px-2" style={{ height: "50px" }}>
        <h1 className="mb-0 text-capitalize text-gradient d-inline-block">
          {title}
        </h1>
      </div>

      <div
        className="col-12 d-flex flex-row mt-3
        justify-content-center align-items-center"
        style={{
          height: "85%",
          overflowY: "auto",
        }}
      >
        <section className="row col-12 h-100">
          <TemplateForm
            handleCallback={onCreateTemplate}
            handleCancel={onCancel}
          />
        </section>
      </div>
    </div>
  );
};

export default SingleTemplate;
