import { 
  SET_SUPER_FETCH, 
  SET_INPUTS, 
  SET_OUTPUTS, 
  SET_LOADING, 
  SET_FEEDBACK_CHANGES, 
  SET_FEEDBACK_FORM,
  SET_FEEDBACK,
  SET_SMALL_DEVICE,
  SET_ENHANCED_PROMPT
} from "../types";

const SuperFetchReducer = (state, { type, payload }) => {
  switch (type) {
    case SET_SUPER_FETCH:
      return { ...state, superFetch: payload }
    case SET_ENHANCED_PROMPT:
        return { ...state, enhancedPrompt: payload }
    case SET_INPUTS: 
      return { ...state, inputs: payload }
    case SET_OUTPUTS: 
      return { ...state, outputs: payload }
    case SET_LOADING: 
      return { ...state, loading: payload }
    case SET_FEEDBACK_CHANGES: 
      return { ...state, feedback: {
        ...state.feedback,
        input: payload
      }}
    case SET_FEEDBACK_FORM: 
      return { ...state, feedback: {
        ...state.feedback,
        formActive: payload
      }}
    case SET_FEEDBACK: 
      return { ...state, feedback: payload }
    case SET_SMALL_DEVICE: 
      return { ...state, smallDevice: payload }
    default:
      return { ...state };
  }
};
export default SuperFetchReducer;
