import React, { useContext, useState } from "react";
import { SuperFetchContext } from "../../context/SuperFetchContext";

const SuperFetchOutputContainer = ({ children }) => {
  const { loading, smallDevice } = useContext(SuperFetchContext);

  const renderLoading = () => {
    if(loading){
      return(
        <div className="loading mb-3">
          <span>Hold tight, Bemodo is working on your instruction</span>
        </div>
      )
    }
  }

  return(
    <div className={`col-12 col-xl-6 p-4 ${smallDevice ? 'h-50 pt-0 px-0' : 'h-100'}`} >
      <div className="card bg-light h-100" style={{overflow: 'hidden'}}>
        <div className="card-header">
          <h3 className="m-0">
            Bemodo AI
          </h3>
        </div>

        <div className="card-body" style={{overflowY: 'auto'}}>
          {renderLoading()}
          {children}
        </div>
      </div>
    </div>
  )
};

export default SuperFetchOutputContainer;
