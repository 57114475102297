import React, { useContext, useEffect } from "react";
import OrganizationGroupTable from "../../../components/organization/group/OrganizationGroupTable";
import OrganizationTableRow from "../../../components/organization/organizations/OrganizationTableRow";
import { AuthContext } from "../../../context/AuthContext";
import OrganizationGroupHeader from "../../../components/organization/group/OrganizationGroupHeader";
import { GroupsContext } from "../../../context/GroupsContext";

const SingleOrganizationGroup = ({ groupId }) => {
  const { user } = useContext(AuthContext);
  const { getSingleGroup, group, setGroup } = useContext(GroupsContext);

  useEffect(() => {
    getSingleGroup(groupId);

    return () => {
      setGroup(null);
    }
  }, []);


  const renderGroupUsers = () => {
    return group?.group_users?.map(obj => {
      const { user } = obj;
      
      return(
        <OrganizationTableRow 
          key={user.user_id} 
          user={user}
          role={'admin'}
          link={`/organization/groups/${group.group_id}/users/${user?.user_id}`}
        />
      )
    })
  }

  return(
    <div className="container-fluid p-3 mb-3 rounded-3 bg-white h-100">
      <OrganizationGroupHeader/>

      <div className="row">
        <OrganizationGroupTable>
          {renderGroupUsers()}
        </OrganizationGroupTable>
      </div>

			
		</div>
  )
}

export default SingleOrganizationGroup;