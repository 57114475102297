import React, { useRef, useContext, useEffect, useState } from "react";
import { TemplatesContext } from "../../context/TemplatesContext";
import { getValue } from "../../utils";
import TagForm from "../global/TagForm";
import { AuthContext } from "../../context/AuthContext";
import ReactSwitch from "react-switch";
import TemplateFieldCard from "./TemplateFieldCard";

const TemplateForm = ({ handleCancel, handleCallback }) => {
  const [validName, setValidName] = useState(true);
  const [validContent, setValidContent] = useState(true);
  const [validDesc, setValidDesc] = useState(true);
  const [repeatedFields, setRepeatedFields] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  const descriptionInput = useRef(null);
  const contentInput = useRef(null);

  const { user } = useContext(AuthContext);
  const { template, spinner, setTemplate, saveTemplate, setPropertyTemplate } =
    useContext(TemplatesContext);

  useEffect(() => {
    setPropertyTemplate("language", "en");

    return () => setTemplate(null);
  }, []);

  useEffect(() => {
    if (template?.template_id) {
      setIsEditing(true);
    }

    if (template && template !== null) {
      setTimeout(() => {
        setTextAreasHeight();
      }, 500);
    }
  }, [template]);

  useEffect(() => {
    if (isEditing) {
      getFieldsValidation();
    }
  }, [isEditing]);

  const getFieldsValidation = () => {
    const updatedFields = [];

    template?.fields?.forEach((field) => {
      let newOpts = [];

      if (field.type === "select") {
        newOpts = field.options.map((opt) => {
          return {
            name: opt,
            state: "valid",
            validating: false,
          };
        });
      }

      updatedFields.push({
        ...field,
        options: newOpts,
        isValidLabel: true,
        isValidField: true,
        validOptionsLength: true,
      });
    });

    setPropertyTemplate("fields", updatedFields);
  };

  const setTextAreasHeight = () => {
    if (contentInput.current !== null && descriptionInput.current !== null) {
      if (template.content === "") {
        contentInput.current.style.height = "0px";
      } else {
        contentInput.current.style.height =
          contentInput.current.scrollHeight + "px";
      }

      if (template.description === "") {
        descriptionInput.current.style.height = "0px";
      } else {
        descriptionInput.current.style.height =
          descriptionInput.current.scrollHeight + "px";
      }
    }
  };

  const validateTemplateFields = () => {
    let templateFieldsValid = true;
    let updatedFields = [];

    template.fields.forEach((field, fieldIndex) => {
      let validField = true;
      let updatedFieldOpts = [];
      let validLabel = true;

      if (field.label.length <= 0) {
        templateFieldsValid = false;
        validField = false;
        validLabel = false;
      }

      if (field.type === "select") {
        if (field.options.length > 0) {
          field.options.forEach((opt, optIndex) => {
            if (opt.name.length <= 0) {
              const updatedOpt = {
                ...opt,
                state: "invalid",
                validating: true,
              };
              updatedFieldOpts.push(updatedOpt);
              templateFieldsValid = false;
              validField = false;
            } else {
              updatedFieldOpts.push(opt);
            }
          });
        } else {
          templateFieldsValid = false;
          validField = false;
        }
      }

      const newFieldData = {
        ...field,
        options: updatedFieldOpts,
        isValidField: validField,
        isValidLabel: validLabel,
      };

      updatedFields.push(newFieldData);
    });

    setPropertyTemplate("fields", updatedFields);
    return templateFieldsValid;
  };

  const validateTemplateData = () => {
    let isTemplateDataValid = true;

    if (template.name?.length <= 0 || template.name === undefined) {
      setValidName(false);
      isTemplateDataValid = false;
    }

    if (template.content?.length <= 0 || template.content === undefined) {
      setValidContent(false);
      isTemplateDataValid = false;
    }

    if (
      template.description?.length <= 0 ||
      template.description === undefined
    ) {
      setValidDesc(false);
      isTemplateDataValid = false;
    }

    return isTemplateDataValid;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let templateFieldsValid = true;
    let templateDataValid;

    if (template?.fields?.length > 0) {
      templateFieldsValid = validateTemplateFields();
    }

    templateDataValid = validateTemplateData();

    if (templateFieldsValid && templateDataValid) {
      const updatedFields = convertOptionsToString();
      const updatedTemplateFields = {
        ...template,
        fields: updatedFields,
      };

      saveTemplate(updatedTemplateFields, handleCallback);
    }
  };

  const convertOptionsToString = () => {
    const newTemplateFields = [];

    template.fields.forEach((field, fieldIndex) => {
      const updatedField = {
        ...field,
        options: "",
        order: fieldIndex + 1,
      };

      if (field.type === "select") {
        let optionsString = "";

        field.options.forEach((opt) => {
          optionsString += `${opt.name}/`;
        });

        updatedField.options = optionsString;
      }

      newTemplateFields.push(updatedField);
    });

    return newTemplateFields;
  };

  const getCurrentFields = (words) => {
    const currentFields = words.map((word) => {
      const fieldName = word.substring(1, word.length - 1);
      let fieldAlreadyCreated = null;

      template.fields?.forEach((field) => {
        if (field.name === fieldName) fieldAlreadyCreated = field;
      });

      if (fieldAlreadyCreated !== null) {
        return fieldAlreadyCreated;
      } else {
        if (fieldName.length > 0) {
          return {
            name: fieldName,
            label: "",
            type: "text",
            options: "",
            isValidField: true,
            isValidLabel: true,
            invalidMax: false,
            invalidMin: false,
            validOptionsLength: true,
          };
        }
      }
    });

    return currentFields;
  };

  const handleChangeContent = (e) => {
    setPropertyTemplate("content", e.target.value);

    const content = e.target.value;
    const bracketsContentExpression = /\[(.*?)\]/g;
    const wordsWithinBrackets = content.match(bracketsContentExpression);

    content.length > 0 ? setValidContent(true) : setValidContent(false);

    if (wordsWithinBrackets) {
      const currentFields = getCurrentFields(wordsWithinBrackets);

      let haveRepeatedFields = false;
      const newFields = currentFields.filter((field, fieldIndex) => {
        let validField = true;

        const copyCurrentFields = [...currentFields];
        copyCurrentFields.splice(fieldIndex, 1);

        const equalsFields = copyCurrentFields.find((obj) => {
          return obj?.name === field?.name;
        });

        if (equalsFields !== undefined) {
          haveRepeatedFields = true;
        }

        if (field === undefined) {
          validField = false;
        }

        if (validField) return field;
      });

      if (haveRepeatedFields) {
        setPropertyTemplate("fields", []);
        setRepeatedFields(true);
      } else {
        setRepeatedFields(false);
        setPropertyTemplate("fields", newFields);
      }
    } else {
      setPropertyTemplate("fields", []);
    }
  };

  const renderPublicToggle = () => {
    if (user.staff && user.staff !== null) {
      return (
        <div className="row">
          <div className="col-6">
            <label>Set Template as Public</label>
          </div>
          <div className="col-6 text-end">
            <ReactSwitch
              checked={getValue(template, "public", "boolean")}
              onChange={(checked) => setPropertyTemplate("public", checked)}
            />
          </div>
        </div>
      );
    }
  };

  const renderFields = () => {
    if (Array.isArray(template?.fields)) {
      
      template?.fields?.sort((a, b) => {
        return a.order - b.order;
      });

      return template?.fields?.map((field, index) => {
        return (
          <div key={index} className="mt-2">
            <TemplateFieldCard field={field} fieldIndex={index} />
          </div>
        );
      });
    }
  };

  const handleChangeDescription = (event) => {
    const currentName = event.target.value;
    setPropertyTemplate("description", currentName);

    currentName.length > 0 ? setValidDesc(true) : setValidDesc(false);
  };

  const handleChangeName = (event) => {
    const currentName = event.target.value;
    setPropertyTemplate("name", currentName);

    currentName.length > 0 ? setValidName(true) : setValidName(false);
  };

  const handleChangeTemplateLang = (lang) => {
    setPropertyTemplate("language", lang);
  };

  return (
    <form onSubmit={handleSubmit} className="col-12 px-0">
      <div className="w-100 row justify-content-between align-items-start">
        {/*Form Left Side */}
        <section className="col-12 col-xl-6">
          <div className={` mb-4 w-100`}>
            <label className="mb-1">Template Name</label>
            <input
              type="text"
              value={getValue(template, "name")}
              className={`form-control ${
                validName ? "" : "border border-danger"
              }`}
              onChange={handleChangeName}
            />

            <span
              className={`text-danger 
              ${validName ? "d-none" : ""}`}
            >
              The input can't be empty
            </span>
          </div>

          <div className={` mb-4 w-100`}>
            <label className="mb-1">Template Description</label>
            <textarea
              ref={descriptionInput}
              value={getValue(template, "description")}
              className={`form-control ${
                validDesc ? "" : "border border-danger"
              }`}
              onChange={handleChangeDescription}
            />

            <span
              className={`text-danger 
              ${validDesc ? "d-none" : ""}`}
            >
              The input can't be empty
            </span>
          </div>

          <TagForm
            tagValues={template?.tags}
            modifier={(tags) => setPropertyTemplate("tags", tags)}
          />

          <div className={` mb-4 w-100`}>
            <label>Content</label>
            <label className="small text-muted d-block mb-1">
              This is where you write your prompt using {"[brackets]"} for your
              custom fields.
            </label>
            <textarea
              ref={contentInput}
              className={`form-control ${
                validContent ? "" : "border border-danger"
              }
              ${repeatedFields ? "border border-danger" : ""}`}
              value={getValue(template, "content")}
              onChange={handleChangeContent}
            />
            <span
              className={`text-danger 
              ${validContent ? "d-none" : ""}`}
            >
              The input can't be empty
            </span>
            <span
              className={`text-danger 
              ${repeatedFields ? "" : "d-none"}`}
            >
              Content can't have repeated fields
            </span>
          </div>

          <label>Type of Content</label>
          <label className="small text-muted d-block mb-1">
            You can classify this template as "Ad", "Blog Post", "Caption", etc.
          </label>
          <input
            type="text"
            className="form-control mb-4"
            value={getValue(template, "type")}
            onChange={(e) => setPropertyTemplate("type", e.target.value)}
          />

          <section className="d-flex flex-column mb-3">
            <label>Template Language</label>
            <div className="btn-group border br-25" role="group">
              <button
                type="button"
                onClick={() => handleChangeTemplateLang("en")}
                className={`btn ${
                  template?.language === "en" ? "btn-primary" : "bg-white"
                }`}
              >
                EN - English
              </button>

              <button
                type="button"
                onClick={() => handleChangeTemplateLang("es")}
                className={`btn ${
                  template?.language === "es" ? "btn-primary" : "bg-white"
                }`}
              >
                ES - Español
              </button>
            </div>
          </section>

          {renderPublicToggle()}
        </section>

        {/* Form Right Side - Fields*/}
        <section
          className={`col-12 col-xl-6 px-3 ${
            template?.fields?.length > 0 ? "" : "d-none"
          }`}
        >
          <label>Fields</label>

          {/* <FieldCard fields={fields}/> */}
          {renderFields()}
        </section>
      </div>

      {/* Form Botton */}
      <section className="row mt-3">
        <div className="col-6">
          {" "}
          <button
            type="button"
            onClick={handleCancel}
            className="btn btn-link w-100 text-muted"
          >
            Cancel
          </button>
        </div>
        <div className="col-6">
          <button
            type="submit"
            disabled={spinner}
            className="btn btn-primary w-100"
          >
            {spinner ? <div className="spinner-border"></div> : "Save"}
          </button>
        </div>
      </section>
    </form>
  );
};

export default TemplateForm;
