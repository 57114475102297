import React, { useContext, useEffect } from "react";
import { WorkflowsContext } from "../context/WorkflowsContext";
import WorkflowList from "../components/workflows/WorkflowList";
import useTranslations from "../hooks/useTranslations";

const Workflows = () => {
  const translations = useTranslations();
  const { workflows, getWorkflows } = useContext(WorkflowsContext);

  useEffect(() => {
    getWorkflows();
  }, []);

  return (
    <div className="container-fluid bg-white p-4 card">
      <h1>{translations.workflows.title}</h1>
      <WorkflowList workflows={workflows} />
    </div>
  );
};

export default Workflows;
