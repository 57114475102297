import { useContext, useEffect } from "react";
import OrganizationForm from "../../../components/organization/organizations/OrganizationForm";
import { OrganizationsContext } from "../../../context/OrganizationsContext";
import OrganizationsHandler from "../../../components/organization/organizations/OrganizationHandler";
import useWindowSize from "../../../hooks/useWindowSize";

const OrganizationSettings = ({ organization_id }) => {
    const { organizationToEdit, getSingleOrganization, saveOrganization } = useContext(OrganizationsContext);
    const isSmallDevice = useWindowSize(1200);

    useEffect(() => {
        const editMode = true
        getSingleOrganization(organization_id, editMode);
    }, [organization_id]);

    const handleCancel = () => {
        window.history.go(-1);
    };

    const { handleDeleteOrganization } = OrganizationsHandler();

    const onSave = () => {
        saveOrganization(organizationToEdit);
        window.history.go(-1)
    };

    const renderEditForm = () => {
        if (organizationToEdit != null) {
            return (
                <>
                    <p className="bold">Edit {organizationToEdit?.name}</p>
                    <OrganizationForm
                        organizationToEdit={organizationToEdit}
                        onDelete={handleDeleteOrganization}
                    />
                </>
            );
        }
    };

    const renderSaveButton = () => {
        if (organizationToEdit != null) {
            return <button
                type="button"
                className={`col-12 btn btn-primary col-md-6`}
                onClick={() => onSave(organizationToEdit)}
            >
                <i className="fas fa-save me-2"></i> Save
            </button>
        }
    }

    return (
        <div className="d-flex flex-column h-100 w-100">
            <h1>Organization Settings</h1>
            <div
                className="card shadow-sm w-100"
                style={{ overflowY: "auto", flex: 1, padding: !isSmallDevice ? "1.5rem" : "1rem" }}
            >
                {renderEditForm()}
                <div className="row p-0">
                    <button
                        type="button"
                        className={`btn  col-12 col-md-6`}
                        onClick={handleCancel}
                    >
                        Cancel
                    </button>
                    {renderSaveButton()}
                </div>
            </div>
        </div>
    );
};

export default OrganizationSettings;
