import { Link, useLocation } from "@reach/router";
import useWindowSize from "../../hooks/useWindowSize";

const BreadcrumbNav = () => {
    const location = useLocation();
    const isSmallDevice = useWindowSize(1200)
    let currentLink = "";

    const crumbs = location.pathname.split("/").filter(crumb => crumb !== '');

    const exceptionRoutes = [
        { route: 'templates', filter: ['templates'] },
        { route: 'organization', filter: ['organization'] },
        { route: 'conversations', filter: ['conversations'], backLink: "/" },
        { route: 'avatars', filter: ['avatars'], backLink: "avatars" },
        { route: 'documents', filter: ['documents'], backLink: "documents" },
        { route: 'organizations/organization-settings', filter: ['organization-settings'], backLink: "organizations" },
        { route: 'organization/admin-panel', filter: [], backLink: "organizations" },
        { route: 'organization/groups', filter: [], backLink: "organizations" },
        { route: 'organization/campaigns', filter: ['organization', 'campaigns'], backLink: "organization/campaigns" },
        { route: 'admin/analytics/income', filter: ['admin', 'analytics', 'income'] },
        { route: 'admin/analytics/products', filter: ['admin', 'analytics', 'products'] },
        { route: 'admin/analytics/customers', filter: ['admin', 'analytics', 'customers'] },
    ];

    const getBreadCrumbsFromUrl = () => {
        const resultCrumbs = exceptionBreadcrumb(crumbs, exceptionRoutes);
        return resultCrumbs;
    };

    const exceptionBreadcrumb = (crumbs, exceptionRoutes) => {
        for (const route of exceptionRoutes) {
            if (location.pathname.includes(route.route)) {

                const filters = route.filter;

                for (const filter of filters) {
                    crumbs = crumbs.filter(crumb => !(crumb.includes(filter) || !(/^[0-9]*$/.test(crumb))));
                }
            }
        }
        return crumbs;
    };

    const renderBackButton = () => {
        for (const route of exceptionRoutes) {
            if (location.pathname.includes(route.route)) {
                const backLink = route.backLink ? route.backLink : '/';

                return <li className="breadcrumb-item">
                    <Link to={backLink}>
                        <i className="fa fa-chevron-left me-1"></i> Back
                    </Link>
                </li>
            }
        }
    };


    const formatCrumbText = (crumb) => {
        const formattedText = crumb
            .replace(/[-%]/g, ' ')
            .split(' ')
            .map(word => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ');

        return formattedText;
    }

    const resultCrumbs = getBreadCrumbsFromUrl();

    const breadcrumbComponents = resultCrumbs.map((crumb, index) => {
        currentLink += `/${crumb}`;
        return (
            <li key={index} className="breadcrumb-item">
                {index === resultCrumbs.length - 1 ? (
                    formatCrumbText(crumb)
                ) : (
                    <Link to={currentLink} style={{ textTransform: 'capitalize' }}>
                        {formatCrumbText(crumb)}
                    </Link>
                )}
            </li>
        );
    });

    const renderBreadCrumbs = () => {
        const resultCrumbs = exceptionBreadcrumb(crumbs, exceptionRoutes);
        if (resultCrumbs.length <= 2) {
            return renderBackButton()
        } else {
            return breadcrumbComponents
        }
    }

    return (breadcrumbComponents.length > 0 ? <nav className="row" aria-label="breadcrumb" style={{ paddingLeft: !isSmallDevice ? "20px" : "12px", maxHeight: !isSmallDevice ? "30px" : "40px" }}>
        <ol className="breadcrumb" style={{ marginBottom: !isSmallDevice ? "20px" : "8px" }}>
            {renderBreadCrumbs()}
        </ol>
    </nav> : <div style={{ display: "none", height: "0" }}></div>
    );
};

export default BreadcrumbNav;