import React, { useEffect, useContext } from "react";
import { AvatarsContext } from "../context/AvatarsContext";
import AvatarForm from "../components/avatars/AvatarForm";
import AvatarList from "../components/avatars/AvatarList";
import AvatarTabs from "../components/avatars/AvatarTabs";
import { ModalContext } from "../context/ModalContext";
import { AuthContext } from "../context/AuthContext";
import Upgrade from "./Upgrade";
import { MenuContext } from "../context/MenuContext";
import useTranslations from "../hooks/useTranslations";
import useWindowSize from "../hooks/useWindowSize";

const Avatars = () => {
  const {
    avatars,
    setAvatar,
    getAvatars,
    clearAvatars,
    createAvatar,
    getPublicAvatars,
  } = useContext(AvatarsContext);
  const translations = useTranslations();
  const { user } = useContext(AuthContext);
  const { setSelected } = useContext(MenuContext);
  const { modalComponent, clearModal } = useContext(ModalContext);

  const smallDevice = useWindowSize(700);

  useEffect(() => {
    fetchAvatars();
    setSelected("Avatars");
  }, [window.location.pathname]);

  const fetchAvatars = (query) => {
    clearAvatars();
    setAvatar(null);
    if (window.location.pathname.includes("community")) {
      getPublicAvatars(query);
    } else {
      getAvatars(query);
    }
  };

  const handleCreate = () => {
    createAvatar();
    modalComponent("Create Avatar", <AvatarForm handleCancel={clearModal} />);
  };
  
  const renderContent = () => {
    if (user.has_access) {
      const isCommunity = window.location.pathname.includes("community");
      const title = isCommunity
        ? translations.avatars.title
        : translations.user_avatars.title;
      return (
        <div>
          <AvatarList
            hideButton={!isCommunity}
            handleCreate={handleCreate}
            fetchAvatars={fetchAvatars}
            avatars={avatars}
            title={title}
          />
        </div>
      );
    }
    return <Upgrade>{renderUpgradeContent()}</Upgrade>;
  };

  const renderUpgradeContent = () => {
    if (window.location.pathname.includes("community")) {
      return (
        <div>
          <p>
            Combining your avatar with known celebrities and content authorities
            like{" "}
            <span className="text-accent">
              Gary Vee, John Maxwell, Barbabra Corcoran and many more...
            </span>{" "}
            requires a valid Master subscription to BemodoAI.
          </p>
        </div>
      );
    }
    return (
      <div>
        <p>
          Creating an avatar who thinks, writes and sounds just like you (or
          even better than you) is a paid feature of BemodoAI.
        </p>
      </div>
    );
  };

  return (
    <div className="container-fluid px-0 h-100" >
      <div 
        className="bg-white shadow card h-100"
        style={{
          overflowY: 'auto',
          padding: !smallDevice ? "1.5rem" : "1rem 0"
        }}
      >
        {renderContent()}
      </div>
    </div>
  );
};

export default Avatars;
