import { HIDE_SPINNER, SHOW_SPINNER } from "../types";
import {
  CREATE_AVATAR,
  AVATARS_RECEIVED,
  SET_AVATAR,
  SET_PROPERTY_AVATAR,
  SET_SUPER_FETCH_AVATAR,
  SET_PUBLIC_AVATARS,
  SET_USER_AVATARS,
} from "../types/avatars";

const schema = {
  avatar_id: "",
  name: "",
};

const TrainingsReducer = (state, { type, payload }) => {
  switch (type) {
    case AVATARS_RECEIVED:
      return { ...state, avatars: payload };
    case SET_AVATAR:
      return { ...state, avatar: payload };
    case SET_PUBLIC_AVATARS:
      return { ...state, publicAvatars: payload };
    case SET_USER_AVATARS:
      return { ...state, userAvatars: payload };
    case SET_SUPER_FETCH_AVATAR:
      return { ...state, super_fetch_avatar: payload };
    case CREATE_AVATAR:
      return { ...state, avatar: schema };
    case SET_PROPERTY_AVATAR:
      const avatar = { ...state.avatar };
      const { key, value } = payload;
      avatar[key] = value;
      return { ...state, avatar };
    case SHOW_SPINNER:
      return { ...state, spinner: true };
    case HIDE_SPINNER:
      return { ...state, spinner: false };
    default:
      return { ...state };
  }
};
export default TrainingsReducer;
