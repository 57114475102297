import React, { useContext, useState } from "react";
import { SuperFetchContext } from "../../context/SuperFetchContext";
import useTranslations from "../../hooks/useTranslations";
import { AvatarsContext } from "../../context/AvatarsContext";

const SuperFetchForm = ({}) => {
  const translations = useTranslations();
  const { inputs, changeInputValue } = useContext(SuperFetchContext);
  const { setSuperFetchAvatar, super_fetch_avatar } =
    useContext(AvatarsContext);

  const handleChangeInput = (event, inputIndex) => {
    const newValue = event.target.value;
    changeInputValue(newValue, inputIndex);
  };

  const handleChangeFile = (event) => {
    const file = event.target.files[0];

    setSuperFetchAvatar({
      ...super_fetch_avatar,
      file,
    });
  };

  const renderTextareaInput = (input, inputIndex) => {
    return (
      <div key={inputIndex} className={` mb-4 w-100`}>
        <label className="mb-1 d-block">
          <b>{input.label}</b>
        </label>
        <small className="form-text text-muted d-block mb-2">
          {input.description}
        </small>
        <textarea
          className={`form-control ${
            input.isValid ? "" : "border border-danger"
          }`}
          value={input.value}
          onChange={(event) => {
            handleChangeInput(event, inputIndex);
          }}
        />
        {input.example && (
          <small className="form-text text-muted d-block mb-2">
            <b>Example:</b> {input.example}
          </small>
        )}
        <span
          className={`text-danger 
          ${input.isValid ? "d-none" : ""}`}
        >
          {translations.superfetch.emptyInput}
        </span>
      </div>
    );
  };

  const renderInputs = () => {
    return inputs.map((input, inputIndex) => {
      if (input.type === "textarea") {
        return renderTextareaInput(input, inputIndex);
      } else {
        return (
          <div key={inputIndex} className={`w-100 mb-4`}>
            <label className="mb-1 d-block">
              <b>{input.label}</b>
            </label>
            <small className="form-text text-muted d-block mb-2">
              {input.description}
            </small>
            <input
              type={input.type}
              className={`form-control z-2 ${
                input.isValid ? "" : "border border-danger"
              }`}
              value={input.value}
              onChange={(event) => {
                handleChangeInput(event, inputIndex);
              }}
            />
            {input.example && (
              <small className="form-text text-muted d-block mb-2">
                <b>Example:</b> {input.example}
              </small>
            )}
            <span
              className={`text-danger 
              ${input.isValid ? "d-none" : ""}`}
            >
              {translations.superfetch.emptyInput}
            </span>
          </div>
        );
      }
    });
  };

  return (
    <div>
      {renderInputs()}
      <div className="px-0">
        <label className="form-label">
          <b>Insert File</b>
        </label>
        <span className="small d-block">
          {" "}
          <span className="bold small">
            {translations.superfetch.fileTypes}
          </span>{" "}
          .docx, .pdf, .pptx, .txt
        </span>
        <input
          // ref={inputRef}
          accept=".docx, .pdf, .pptx, .txt"
          className="form-control rounded-3"
          type="file"
          id="avatar__input-file"
          onChange={handleChangeFile}
          style={{
            paddingLeft: "0px!important",
          }}
        />
      </div>
    </div>
  );
};

export default SuperFetchForm;
