import { Link } from "@reach/router";
import React, { useEffect, useState } from "react";
import { useContext } from "react";
import { OrganizationsContext } from "../../context/OrganizationsContext";
import useLocalStorageOrganization from "../../hooks/organizations/useLocalStorageOrganization";

import { AuthContext } from "../../context/AuthContext";

const OrganizationPicker = () => {
    const [currentOrganizations, setCurrentOrganizations] = useState([]);

    const { organizations, getSingleOrganization, setOrganization, organization, getUserOrganizations } =
        useContext(OrganizationsContext);

    const { getStoredOrganization, storeOrganization } = useLocalStorageOrganization();
    const { user, getCurrentUserOrganization } = useContext(AuthContext);

    useEffect(() => {
        getUserOrganizations(setCurrentOrganizations, '');
        getCurrentUserOrganization(user?.user_id, organization?.organization_id);
    }, [organization]);


    useEffect(() => {
        getUserOrganizations(setCurrentOrganizations, '');

        const storedOrganization = getStoredOrganization()
        if (storedOrganization.organization_id) {
            getSingleOrganization(storedOrganization.organization_id, false);
        } else if (organizations.length < 1) {
            setOrganization({ name: 'Personal', organization_id: null })
        }
    }, [])

    const handleSelectOrganization = (organization) => {
        if (organization.organization_id) {
            getSingleOrganization(organization.organization_id, false);
        } else {
            setOrganization({ name: 'Personal', organization_id: null })
        }

        storeOrganization(organization);
    }

    const renderOrganizationsList = () => {
        return currentOrganizations.map((organization) => {
            if (currentOrganizations != null) {
                return (
                    <li
                        className="my-1"
                        key={organization.name}
                        onClick={() => handleSelectOrganization(organization)}
                    >
                        <div
                            className="dropdown-item hover-success text-dark"
                        >
                            <div className="d-flex">
                                <div className="col-2 me-2">
                                    <i className="fas fa-globe"></i>
                                </div>
                                <div className="col-10">{organization.name}</div>
                            </div>
                        </div>
                    </li>
                );
            }
        });
    };

    return (
        <div>
            <button
                id="navbarDropdown"
                data-bs-toggle="dropdown"
                aria-expanded="false"
                className="btn small my-2 w-100 text-left text-dark ps-3 pe-0"
            >
                <div className="row">
                    <div className="col-2">
                        <i className="fas fa-globe"></i>
                    </div>
                    <div className="col-10 d-flex justify-content-between">
                        {organization ? organization.name : "Organization"}
                        <span className="dropdown-toggle"></span>
                    </div>
                </div>
            </button>
            <ul
                className="dropdown-menu bg-white border"
                aria-labelledby="navbarDropdown"
            >
                {renderOrganizationsList()}
                <li
                    className="my-1"
                    key={organization.name}
                    onClick={() => handleSelectOrganization({ name: "Personal" })}
                >
                    <div
                        className="dropdown-item hover-success text-dark"
                    >
                        <div className="d-flex">
                            <div className="col-2 me-2">
                                <i className="fas fa-user"></i>
                            </div>
                            <div className="col-10">Personal</div>
                        </div>
                    </div>
                </li>
                <li className="my-1">
                    <Link
                        to="/organizations/"
                        className="dropdown-item hover-success text-dark"
                    >
                        <div className="d-flex">
                            <div className="col-2 me-2">
                                <i className="fas fa-plus"></i>
                            </div>
                            <div className="col-10">All</div>
                        </div>
                    </Link>
                </li>
            </ul>
        </div>
    );
};

export default OrganizationPicker;
