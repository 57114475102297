import React, { useContext, useEffect, useState } from "react";
import OrganizationAdminTable from "../../../components/organization/organizations/OrganizationAdminTable";
import OrganizationPanelHeader from "../../../components/organization/organizations/OrganizationPanelHeader";
import OrganizationTableRow from "../../../components/organization/organizations/OrganizationTableRow";
import { OrganizationsContext } from "../../../context/OrganizationsContext";
import PanelTitleDate from "../../../components/global/PanelTitleDate";
import moment from "moment";

const userTableCols = [
  {
    name: '#ID'
  },
  {
    name: 'Name'
  },
  {
    name: 'Email'
  },
  {
    name: 'Role'
  },
  {
    name: 'Total Words'
  },
  {
    name: 'Last used'
  },
]

const OrganizationPanel = ({}) => {
  const [query, setQuery] = useState("");
  const [currentUsers, setCurrentUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);

  const { organization, getSingleOrganization } = useContext(OrganizationsContext);

  useEffect(() => {
    getSingleOrganization(organization.organization_id);
  }, []);

  useEffect(() => {
    hanldeFilterUsers();
  }, [query, organization]);

  const hanldeFilterUsers = () => {
    const organizationUsers = organization.users;

    if(query.length > 0) {
      const filteredUsers = organizationUsers.filter(obj => {
        const userName = obj.user.name?.toLowerCase();
        if(userName?.includes(query.toLowerCase())) return obj; 
      });

      setCurrentUsers(filteredUsers);
    } else {
      setCurrentUsers(organizationUsers);
    }
  }

  function filterUsersByDate(users, startDate, endDate) {
    startDate = new Date(startDate);
    endDate = new Date(endDate);
  
    const filteredUsers = users?.filter(obj => {
      const currentDate = new Date(obj.last_use); 
      return currentDate >= startDate && currentDate <= endDate;
    });
  
    return filteredUsers;
  }

  const renderOrganizationUsers = () => {
    return currentUsers?.map(obj => {
      let lastUseDate;
      let totalWords;

      if(obj.last_use) {
        lastUseDate = moment(obj.last_use).format('YYYY-MM-DD');
      } else {
        lastUseDate = 'No used yet'
      }

      if(obj.total_words) {
        totalWords = obj.total_words;
      } else {
        totalWords = 0;
      }

      obj.user.total_words = totalWords;
      obj.user.last_use = lastUseDate;


      return(
        <OrganizationTableRow 
          key={obj.user_id} 
          extraFields={[{key: 'total_words'}, {key: 'last_use'}]}
          user={obj.user} 
          role={obj.user_type.name}
          totalWords={totalWords}
          lastUse={lastUseDate}
          link={`users/${obj.user?.user_id}`}
        />
      )
    });
  }

  const handleChangeDateInput = (startDate, endDate) => {
    let users;
    currentUsers.length > 0 ? users = currentUsers : users = organization.users;

    const filteredUsers = filterUsersByDate(users, startDate, endDate);
    setCurrentUsers(filteredUsers);
  }

  return (
    <div className="container-fluid bg-white p-3 rounded-3 h-100 d-flex flex-column">
      <OrganizationPanelHeader query={query} setQuery={setQuery}/>
      
      <div className="container-fluid px-0 mt-3" style={{flex: 1, overflowY: 'auto'}}>
        <PanelTitleDate 
          title={'Users'} 
          smallTitle 
          callback={handleChangeDateInput}
        />
        
        <OrganizationAdminTable tableColumns={userTableCols}>
          {renderOrganizationUsers()}
        </OrganizationAdminTable>
      </div>
    </div>
  );
};

export default OrganizationPanel;
