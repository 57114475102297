import React, { useContext, useEffect, useState } from "react";
import { SuperFetchContext } from "../../context/SuperFetchContext";
import useTranslations from "../../hooks/useTranslations";
import { ModalContext } from "../../context/ModalContext";
import { navigate } from "@reach/router";
import { TemplatesContext } from "../../context/TemplatesContext";
import { AvatarsContext } from "../../context/AvatarsContext";
import MessagesService from "../../services/MessagesService";
import { MessagesContext } from "../../context/MessagesContext";

const SuperFetchSaveForm = ({  }) => {
  const [spinner, setSpinner] = useState(false);

  const { 
    inputs, 
    changeInputEditable, 
    getTemplatePrompt,
    getTemplateFields,
  } = useContext(SuperFetchContext);

  const { setEnhancing, enhancing, setEnhanced, enhanced } = useContext(MessagesContext);


  useEffect(() => {
    if(enhanced !== null && enhanced !== undefined){
      handleSave();
    }
  }, [enhanced]);


  const translations = useTranslations();
  const { clearModal } = useContext(ModalContext);
  const { setPropertyTemplate } = useContext(TemplatesContext);

  const hanldeChangeSwitch = (event, inputIndex) => {
    changeInputEditable(event.target.checked, inputIndex);
  }

  const handleSave = () => {
    const fields = getTemplateFields();

    setPropertyTemplate('content', enhanced);
    setPropertyTemplate('fields', fields);

    clearModal();
    navigate('templates/create-template');
  }

  const handleEnhance = () => {
    setEnhancing(true);
    const content = getTemplatePrompt();

    MessagesService.postMessage({
      content: `
        Below I will give you some instructions and considerations so that you can improve the instruction fields taking into account each consideration that I give you: 
        
        Consiter the next to improve the Instrucions:
          -You will give me the same instrucions in the same format
          -You must not modify the content and structure, and you must not add more instructions or lines.
          -Every field begins by a "-", dont modify the fileds and not add more fields, the only thing you will improve is the instruction after each field.
          -Write the instruction to get the result, not the result itself. 
          -Consider the words within brackets will be changed to introduce user inputs in the instruction so dont modify it and use it where i can identify to insert the user inputs  

        Instructions: 
        ${content}
      `,
      enhance: true,
      stream: false,
    });

  };

  const renderInputs = () => {
    return inputs.map((input, inputIndex) => {
      
      return(
        <div key={inputIndex} className={`row mb-3`}>
          <div className="col-10 px-0">
            <label className="mb-1">
              <b className="fs-5">{input.label}</b>
            </label>
          </div>

          <div className="col-2 align-self-end">
            <div className="form-check form-switch">
              <input 
                onChange={(event) => {
                  hanldeChangeSwitch(event, inputIndex);
                }}
                style={{width: '4em', height: '2em'}} 
                className="form-check-input"
                checked={input.editable} 
                type="checkbox" 
                role="switch" 
                id="flexSwitchCheckDefault"
              />
            </div>
          </div>
        </div>
      )
    });
  }

  const renderSpinner = () => {
    if(enhancing) {
      return(
        <div 
          className="spinner-border me-3
          position-absolute top-0 bottom-0 end-0 m-auto"
        ></div>
      )
    } 
  }

  const renderButtons = () => {
    return(
      <div className="row mt-4">
        <div className="col-6 text-end px-0">
          <button
            type="button"
            onClick={clearModal}
            className="btn w-100 text-muted px-0"
          >
            {translations.form.cancel}
          </button>
        </div>

        <div className="col-6 position-relative">
          <button
            type="button"
            disabled={enhancing}
            className="btn w-100 btn-primary"
            onClick={handleEnhance}
          >
            Continue
          </button>

          {renderSpinner()}
        </div>
      </div>
    )
  }
  


  return(
    <div>
      <h4 className="mb-4">Select the inputs you want to be editables</h4>
      <form className="container">  
        {renderInputs()}
        {renderButtons()}
      </form>

    </div>

  )
};

export default SuperFetchSaveForm;
