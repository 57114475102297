import React, { useContext, useEffect } from "react";
import Tags from "../components/global/Tags";
import { ModalContext } from "../context/ModalContext";
import { WorkflowsContext } from "../context/WorkflowsContext";
import UserWorkflowForm from "../components/user_workflows/UserWorkflowForm";
import WorkflowTemplateCard from "../components/workflow_template/WorkflowTemplateCard";
import useTranslations from "../hooks/useTranslations";

const SingleWorkflow = ({ workflow_id }) => {
  const tranlsations = useTranslations();
  const { modalComponent } = useContext(ModalContext);
  const { workflow, getSingleWorkflow } = useContext(WorkflowsContext);

  useEffect(() => {
    getSingleWorkflow(workflow_id);
  }, [workflow_id]);

  const confirmStart = () => {
    modalComponent(
      tranlsations.workflows.start,
      <UserWorkflowForm workflow_id={workflow_id} />
    );
  };

  const renderDetails = () => {
    if (workflow && workflow !== null) {
      return (
        <div className="border-bottom mb-4 pb-4">
          <h1>{workflow.name}</h1>

          <p>{workflow.description}</p>
          <Tags tags={workflow.tags} />

          <div className="row align-items-center">
            <div className="col-12 col-md-6">
              <button onClick={confirmStart} className="btn btn-primary px-4">
                {tranlsations.workflows.start}{" "}
                <i className="fa fa-chevron-right ms-2"></i>
              </button>
            </div>
            <div className="col-12 col-md-6 text-end">
              <button className="btn btn-outline-dark me-2">
                <i className="fa fa-star"></i>
              </button>
              <button className="btn btn-outline-dark">
                <i className="fa fa-bookmark"></i>
              </button>
            </div>
          </div>
        </div>
      );
    }
  };

  const renderTemplates = () => {
    if (workflow && workflow !== null) {
      const { templates } = workflow;
      if (Array.isArray(templates)) {
        return templates.map((template) => (
          <div key={template.template_id} className="col-12 col-md-6 col-xl-4">
            <WorkflowTemplateCard template={template} />
          </div>
        ));
      }
    }
  };

  return (
    <div className="container-fluid card p-5 bg-white">
      {renderDetails()}
      <div className="row">{renderTemplates()}</div>
    </div>
  );
};

export default SingleWorkflow;
