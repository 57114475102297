import React, { useRef, useState, useContext, useEffect } from "react";
import ConversationUpgrade from "../components/conversations/ConversationUpgrade";
import ConversationHeader from "../components/conversations/ConversationHeader";
import MessageInputForm from "../components/messages/MessageInputForm";
import { ConversationsContext } from "../context/ConversationsContext";
import TemplateList from "../components/templates/TemplatesList";
import { TemplatesContext } from "../context/TemplatesContext";
import { MessagesContext } from "../context/MessagesContext";
import MessageCard from "../components/messages/MessageCard";
import useTranslations from "../hooks/useTranslations";
import { AuthContext } from "../context/AuthContext";
import { setupTooltips } from "../utils";
import useEmitSocket from "../hooks/useEmitSocket";
import { navigate } from "@reach/router";

const SingleConversation = ({
  conversation_id,
  disableTemplatesBtn,
  addGenerateBtn,
  handleGenerateBtn,
  titleGradient,
}) => {
  const refContainer = useRef(null);
  const { cancelChatStream } = useEmitSocket();

  const [page, setPage] = useState(1);
  const [firstLoad, setFirstLoad] = useState(true);
  const [smallDevice, setSmallDevice] = useState(false);

  const translations = useTranslations();
  const { user } = useContext(AuthContext);
  const { template, templates, getPublicTemplates } =
    useContext(TemplatesContext);


  const {
    conversation,
    getSingleConversation,
    setConversation,
    setPropertyConversation,
  } = useContext(ConversationsContext);

  const {
    max,
    messages,
    setPrompt,
    generating,
    saveMessage,
    clearMessages,
    getConversationMessages,
    setGenerating
  } = useContext(MessagesContext);

  useEffect(() => {
    handleScreenWidth();
    getSingleConversation(conversation_id);
    getPublicTemplates();

    return () => {
      setConversation(null);
      window.removeEventListener("resize", () => { });
    }
  }, []);

  useEffect(() => {
    clearMessages();
    if (!template || template === null) {
      setPrompt("");
    }
  }, [conversation_id]);

  useEffect(() => {
    setupTooltips();
    if (firstLoad) handleScrollBottom();
  }, [messages]);

  useEffect(() => {
    fetchMessages();
  }, [conversation]);

  const handleScreenWidth = () => {
    window.screen.width >= 1200 ? setSmallDevice(false) : setSmallDevice(true);

    window.addEventListener("resize", () => {
      window.screen.width >= 1200
        ? setSmallDevice(false)
        : setSmallDevice(true);
    });
  };

  const fetchMessages = () => {
    getConversationMessages(conversation_id, { page });
  };

  const handleScrollBottom = () => {
    const container = refContainer.current;
    refContainer.current?.scrollTo({
      top: firstLoad
        ? container.offsetHeight * 10
        : container.offsetHeight * messages.length * 2,
    });
    if (firstLoad) setFirstLoad(false);
  };

  const handleScroll = () => {
    const container = refContainer.current;
    const scrollTop = container.scrollTop;
    if (scrollTop === 0 && Array.isArray(messages)) {
      if (messages.length < max) {
        setPage(page + 1);
      }
    }
  };

  const handleMessage = (prompt) => {
    saveMessage(
      {
        content: prompt,
        conversation_id,
        stream: false,
      },
      fetchMessages
    );
  };

  const renderMax = () => {
    if (Array.isArray(messages)) {
      if (messages.length <= max) {
        return (
          <div className="d-flex mt-3 justify-content-center">
            <span className="border bg-accent badge badge-pill mb-3 m-auto d-inline-block">
              {translations.conversation.max_messages}
            </span>
          </div>
        );
      }
    }
  };

  const renderMessages = () => {
    if (Array.isArray(messages)) {
      return messages.map((message, index) => {
        return (
          <MessageCard
            message={message}
            key={message.message_id}
            handleCallback={fetchMessages}
            prevMessage={messages[index - 1]}
          />
        );
      });
    }
  };

  const renderSpinner = () => {
    if (generating) {
      return (
        <div className="loading mb-3">
          <span>Hold tight, Fetch is working on your instruction</span>
        </div>
      );
    }
  };

  const renderContinue = () => {
    if (!generating && Array.isArray(messages)) {
      const lastMessage = messages[messages.length - 1];
      if (lastMessage && lastMessage !== null) {
        if (lastMessage.finish_reason === "length") {
          return (
            <button
              className="btn border"
              onClick={() => handleMessage("Continue generating last response")}
            >
              <i className="fa fa-forward me-2"></i>{" "}
              {translations.conversation.continue}
            </button>
          );
        }
      }
    }
  };

  const hanldeCancelChatGenerate = () => {
    cancelChatStream();
    setGenerating(false);
  }

  const renderStopGenerating = () => {
    if(generating) {
      return(
        <button 
          className="btn btn-accent small mb-3"
          onClick={hanldeCancelChatGenerate}
        >
          Stop Generating
          <i className="fas fa-stop ms-2"></i>
        </button>
      )
    }
  }

  const handleBack = () => {
    setPropertyConversation("showTemplates", false);
  };

  const renderContent = () => {
    let componentActive = true;
    if (conversation?.showTemplates && smallDevice) componentActive = false;

    if (!user.has_access) {
      return <ConversationUpgrade />;
    }

    if (componentActive) {
      return (
        <div
          className={`position-relative h-100 d-flex  flex-column flex-nowrap align-items-center px-0
          ${conversation?.showTemplates ? "col-12 col-md-6 " : "col-12"}  
          `}
          style={{
            order: 1,
          }}
        >
          <ConversationHeader titleGradient={titleGradient} />
          <div
            className="row overflow-hidden position-relative"
            style={{
              flex: 1,
            }}
          >
            <div id="messages" ref={refContainer} className="px-4 h-100">
              {renderMax()}
              {renderMessages()}
              {renderSpinner()}
              {renderContinue()}
              {renderStopGenerating()}
            </div>
          </div>
          <MessageInputForm
            disableTemplatesBtn={disableTemplatesBtn}
            spinner={generating}
            handleSubmit={handleMessage}
            addGenerateBtn={addGenerateBtn}
            handleGenerateBtn={handleGenerateBtn}
          />
        </div>
      );
    }
  };

  const handleApply = (templateId) => {
    navigate(`/templates/apply-template/${templateId}/${conversation_id}`);
  };

  const fetchTemplates = (params) => {
    getPublicTemplates(params);
  };

  const renderTemplates = () => {
    if (conversation?.showTemplates) {
      return (
        <article
          className="h-100 br-10 bg-white position-relative px-3 py-3
          col-12 col-xl-5 conversation__templates-list"
          style={{
            order: 2,
          }}
        >
          <TemplateList
            title={translations.templates.title}
            templates={templates}
            handleApply={handleApply}
            fetchTemplates={fetchTemplates}
            handleBackBtn={handleBack}
            disableAddBtn
            filtersActive
            titleGradient
            backBtn={smallDevice}
          />
        </article>
      );
    }
  };

  return (
    <div
      id="conversation"
      onScroll={handleScroll}
      className=" h-100 d-flex row justify-content-around"
      style={{
        overflowY: "auto",
        overflowX: "hidden",
      }}
    >
      {renderTemplates()}
      {renderContent()}
    </div>
  );
};

export default SingleConversation;
