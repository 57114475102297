import React, { useState, useContext, useEffect } from "react";
import { CampaignsContext } from "../../../context/CampaignsContext";
import CampaignContentList from "../../../components/organization/campaign/CampaignContentList";
import CampaignActions from "../../../components/organization/campaign/CampaignActions";
import useWindowSize from "../../../hooks/useWindowSize";

const SingleCampaign = ({ campaign_id }) => {

    const { getSingleCampaign, campaign } = useContext(CampaignsContext);
    const [query, setQuery] = useState("");
    const [filter, setFilter] = useState("document");

    const isSmallDevice = useWindowSize(1200);

    useEffect(() => {
        getSingleCampaign(campaign_id, filter, query);
    }, [filter, query]);

    const renderCampaignContent = () => {
        if (campaign && campaign.hasOwnProperty(filter)) {
            return <CampaignContentList content={campaign[filter]} type={filter} />;
        }
    };

    return (
        <div
            className="container-fluid bg-white card position-relative h-100"
            style={{ padding: !isSmallDevice ? "1.5rem" : "1rem" }}
        >
            <div className="row mb-3">
                <div className="col-12  ps-0">
                    <h1 className="mb-0 h2 text-capitalize text-gradient d-inline-block">
                        {campaign ? campaign.name : campaign_id}
                    </h1>
                </div>
                <div className="col-12 mb-2 col-md-12 col-xl-8 px-0 row">
                    <div className="col-12 col-md-6 px-0 mt-2">
                        <input
                            type="text"
                            value={query}
                            className="form-control"
                            placeholder={`Search ${filter}s...`}
                            onChange={(e) => setQuery(e.target.value)}
                        />
                    </div>
                    <div
                        className="col-12 col-md-6"
                        style={
                            isSmallDevice
                                ? {
                                    marginBottom: "2px",
                                    textAlign: "end",
                                    justifyContent: "flex-end",
                                    display: "flex",
                                    paddingRight: "0",
                                }
                                : { justifyContent: "flex-end", display: "flex", paddingRight: "0" }
                        }
                    >
                        <CampaignActions filter={filter} setFilter={setFilter} />
                    </div>
                </div>
            </div>
            {renderCampaignContent()}
        </div>
    );
};

export default SingleCampaign;
