import React, { useState, useEffect, useContext } from "react";
import { TemplatesContext } from "../context/TemplatesContext";
import TemplateTagCard from "../components/templates/TemplateTagCard";
import useTranslations from "../hooks/useTranslations";

const TemplateTags = ({ size }) => {
  const [query, setQuery] = useState("");
  const translations = useTranslations();
  const { tags, spinner, clearTemplates, getAllTags } =
    useContext(TemplatesContext);

  useEffect(() => {
    if (query === "") {
      clearTemplates();
    }
    if (!spinner) {
      getAllTags({ query });
    }
  }, [query]);

  const renderTemplates = () => {
    if (Array.isArray(tags)) {
      if (tags.length === 0) {
        return <p>No templates available.</p>;
      }
      return tags.map((tag) => <TemplateTagCard key={tag} tag={tag} />);
    }
    if (spinner) return <div className="spinner-border"></div>;
  };

  return (
    <div id="templates" className="container-fluid ps-0">
      <h1 className="mb-0 text-capitalize text-gradient d-inline-block">
        Templates by Tags
      </h1>
      <input
        type="text"
        value={query}
        className="form-control my-3"
        placeholder={translations.templates.search}
        onChange={(e) => setQuery(e.target.value)}
      />
      <div className="row">{renderTemplates()}</div>
    </div>
  );
};

export default TemplateTags;
