import React, { useContext, useEffect } from "react";
import TemplateList from "../components/templates/TemplatesList";
import { TemplatesContext } from "../context/TemplatesContext";
import useTranslations from "../hooks/useTranslations";
import { setupTooltips } from "../utils";

const Templates = () => {
  const translations = useTranslations();
  const { templates, getPublicTemplates } = useContext(TemplatesContext);

  useEffect(() => {
    setupTooltips();
  }, []);

  const fetchTemplates = (params) => {
    getPublicTemplates(params);
  };

  return (
    <div className="px-3 py-3 card bg-white active-scroll-y h-100">
      <TemplateList
        size="lg"
        title={translations.templates.title}
        templates={templates}
        fetchTemplates={fetchTemplates}
        titleGradient
        defaultSelected={'type'}
      />
    </div>
  );
};

export default Templates;
