import React, { createContext, useReducer, useContext } from "react";
import TemplatesService from "../services/TemplatesService";
import TemplatesReducer from "../reducers/TemplatesReducer";

import {
  SET_TEMPLATE,
  CREATE_TEMPLATE,
  TEMPLATES_RECEIVED,
  SET_PROPERTY_TEMPLATE,
  TAGS_RECEIVED,
  TYPES_RECEIVED,
  SET_TOTAL,
  SET_USER_TEMPLATES,
  SET_TEMPLATES_TAB,
} from "../types/templates";
import { ModalContext } from "./ModalContext";
import { HIDE_SPINNER, SHOW_SPINNER } from "../types";
import useTranslations from "../hooks/useTranslations";
import useLocalStorageOrganization from "../hooks/organizations/useLocalStorageOrganization";

const initialState = {
  user_templates: null,
  templates: null,
  template: null,
  spinner: false,
  templates_tab: 'type'
};

export const TemplatesContext = createContext(initialState);

export const TemplatesProvider = ({ children }) => {
  const [state, dispatch] = useReducer(TemplatesReducer, initialState);
  const { getStoredOrganization } = useLocalStorageOrganization();
  const { alert, success, clearModal } = useContext(ModalContext);

  const translations = useTranslations();

  const getOptionsFromString = (optionsString) => {
    const optionsArray = optionsString.split("/");
    optionsArray.splice(optionsArray.length - 1, 1);

    return optionsArray;
  };


  const getTemplateFieldsOptions = (currentTemplate) => {
    const newFields = currentTemplate.template_fields.map((field) => {
      const newField = {
        ...field,
        value: "",
        isValidField: true,
      };

      if (field.type === "select") {
        const currentOptions = getOptionsFromString(field.options);

        newField.options = currentOptions;
        newField.value = currentOptions[0];

        return newField;
      } else {
        return newField;
      }
    });

    const updatedTemplate = {
      ...currentTemplate,
      fields: newFields,
    };

    return updatedTemplate;
  };

  const getTemplates = (filters) => {
    dispatch({ type: SHOW_SPINNER });
    TemplatesService.getTemplates(filters)
      .then((response) => {
        dispatch({ type: HIDE_SPINNER });
        const { templates } = response.data;
        dispatch({ type: TEMPLATES_RECEIVED, payload: templates });
      })
      .catch((error) => {
        dispatch({ type: HIDE_SPINNER });
        alert(error);
      });
  };

  const getAllTags = (filters) => {
    dispatch({ type: SHOW_SPINNER });
    const storedOrganization = getStoredOrganization()
    if (storedOrganization) {
      filters.organization_id = storedOrganization.organization_id
    }
    TemplatesService.getAllTags(filters)
      .then((res) => {
        dispatch({ type: HIDE_SPINNER });
        const { tags, total } = res.data;
        dispatch({ type: SET_TOTAL, payload: total });
        dispatch({ type: TAGS_RECEIVED, payload: tags });
      })
      .catch((error) => {
        dispatch({ type: HIDE_SPINNER });
        alert(error);
      });
  };

  const getAllTypes = (filters) => {
    dispatch({ type: SHOW_SPINNER });
    const storedOrganization = getStoredOrganization()
    if (storedOrganization) {
      filters.organization_id = storedOrganization.organization_id
    }
    TemplatesService.getAllTypes(filters)
      .then((res) => {
        dispatch({ type: HIDE_SPINNER });
        const { types, total } = res.data;
        dispatch({ type: SET_TOTAL, payload: total });
        dispatch({ type: TYPES_RECEIVED, payload: types });
      })
      .catch((error) => {
        dispatch({ type: HIDE_SPINNER });
        alert(error);
      });
  };

  const getTemplatesByTag = (tag, filters) => {
    dispatch({ type: SHOW_SPINNER });
    TemplatesService.getTemplatesByTag(tag, filters)
      .then((response) => {
        dispatch({ type: HIDE_SPINNER });
        const { templates, total } = response.data;
        dispatch({ type: SET_TOTAL, payload: total });
        dispatch({ type: TEMPLATES_RECEIVED, payload: templates });
      })
      .catch((error) => {
        dispatch({ type: HIDE_SPINNER });
        alert(error);
      });
  };

  const getTemplatesByType = (type, filters) => {
    dispatch({ type: SHOW_SPINNER });
    const storedOrganization = getStoredOrganization()
    if (storedOrganization) {
      filters.organization_id = storedOrganization.organization_id
    }
    TemplatesService.getTemplatesByType(type, filters)
      .then((response) => {
        dispatch({ type: HIDE_SPINNER });
        const { templates, total } = response.data;
        dispatch({ type: SET_TOTAL, payload: total });
        dispatch({ type: TEMPLATES_RECEIVED, payload: templates });
      })
      .catch((error) => {
        dispatch({ type: HIDE_SPINNER });
        alert(error);
      });
  };

  const getUserTemplates = () => {
    dispatch({ type: SHOW_SPINNER });
    TemplatesService.getUserTemplates()
      .then((response) => {
        dispatch({ type: HIDE_SPINNER });
        const { templates } = response.data;
        dispatch({ type: SET_USER_TEMPLATES, payload: templates });
      })
      .catch((error) => {
        dispatch({ type: HIDE_SPINNER });
        alert(error);
      });
  };

  const getPublicTemplates = (filters = {}) => {
    const storedOrganization = getStoredOrganization()
    if (storedOrganization) {
      filters.organization_id = storedOrganization.organization_id
    }
    dispatch({ type: SHOW_SPINNER });
    TemplatesService.getPublicTemplates(filters)
      .then((response) => {
        dispatch({ type: HIDE_SPINNER });
        const { templates, total } = response.data;
        dispatch({ type: SET_TOTAL, payload: total });
        dispatch({ type: TEMPLATES_RECEIVED, payload: templates });
      })
      .catch((error) => {
        dispatch({ type: HIDE_SPINNER });
        alert(error);
      });
  };

  const setTemplates = (payload) => {
    dispatch({ type: TEMPLATES_RECEIVED, payload });
  }

  const getAllTemplates = (filters) => {
    dispatch({ type: SHOW_SPINNER });
    const storedOrganization = getStoredOrganization()
    if (storedOrganization) {
      filters.organization_id = storedOrganization.organization_id
    }
    TemplatesService.getAllTemplates(filters)
      .then((response) => {
        dispatch({ type: HIDE_SPINNER });
        const { templates } = response.data;
        dispatch({ type: TEMPLATES_RECEIVED, payload: templates });
      })
      .catch((error) => {
        dispatch({ type: HIDE_SPINNER });
        alert(error);
      });
  };

  const getSingleTemplate = (template_id) => {
    TemplatesService.getSingleTemplate(template_id)
      .then((response) => {
        const { template } = response.data;
        const updatedTemplate = getTemplateFieldsOptions(template);
        dispatch({ type: SET_TEMPLATE, payload: updatedTemplate });
      })
      .catch((error) => {
        alert(error);
      });
  };

  const setTemplate = (template) => {
    dispatch({ type: SET_TEMPLATE, payload: template });
  };

  const setTemplatesTab = (tab) => {
    dispatch({ type: SET_TEMPLATES_TAB, payload: tab })
  }

  const createTemplate = () => {
    dispatch({ type: CREATE_TEMPLATE });
  };

  const setPropertyTemplate = (key, value) => {
    dispatch({ type: SET_PROPERTY_TEMPLATE, payload: { key, value } });
  };

  const clearTemplates = () => {
    dispatch({ type: TEMPLATES_RECEIVED, payload: null });
  };

  const saveTemplate = (template, callback) => {

    const storedOrganization = getStoredOrganization()

    if (storedOrganization) {
      template.organization_id = storedOrganization.organization_id
    }

    dispatch({ type: SHOW_SPINNER });
    let service = TemplatesService.putTemplate;
    if (isNaN(parseInt(template.template_id))) {
      service = TemplatesService.postTemplate;
    }

    service(template)
      .then(() => {
        success(translations.templates.saved);
        dispatch({ type: HIDE_SPINNER });
        if (typeof callback === "function") {
          callback();
        }
      })
      .catch((error) => {
        dispatch({ type: HIDE_SPINNER });
        alert(error);
      });
  };

  const toggleFavorite = (template_id, callback) => {
    TemplatesService.favoriteTemplate(template_id).then(() => {
      success(translations.templates.favorite_saved);
      if (typeof callback === "function") {
        callback();
      }
    });
  };

  const deleteTemplate = (template_id, callback) => {
    dispatch({ type: SHOW_SPINNER });
    TemplatesService.deleteTemplate(template_id)
      .then(() => {
        success(translations.templates.deleted);
        dispatch({ type: HIDE_SPINNER });
        clearModal();
        if (typeof callback === "function") {
          callback();
        }
      })
      .catch((error) => {
        dispatch({ type: HIDE_SPINNER });
        alert(error);
      });
  };

  return (
    <TemplatesContext.Provider
      value={{
        ...state,
        getAllTags,
        getAllTypes,
        setTemplate,
        getTemplates,
        saveTemplate,
        toggleFavorite,
        clearTemplates,
        deleteTemplate,
        createTemplate,
        getAllTemplates,
        getTemplatesByTag,
        getSingleTemplate,
        getTemplatesByType,
        getPublicTemplates,
        setPropertyTemplate,
        getUserTemplates,
        setTemplatesTab,
        setTemplates
      }}
    >
      {children}
    </TemplatesContext.Provider>
  );
};
