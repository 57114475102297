import React from "react";

const TemplateTypeCard = ({ type, size, setCurrentType }) => {
  return (
    <div 
      onClick={() => setCurrentType(type)} 
      className={`col-12 ps-0 ${size === "lg" ? "col-xxl-4 col-xl-6" : ""} cursor-pointer`}
    >
      <div className="card px-4 py-5 text-center mb-3 bg-light">
        <h4 className="text-capitalize">{type}</h4>
      </div>
    </div>
  );
};

export default TemplateTypeCard;
