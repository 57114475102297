import React, { useRef, useState, useContext, useEffect } from "react";
import { ConversationsContext } from "../context/ConversationsContext";
import { UserWorkflowsContext } from "../context/UserWorkflowsContext";
import { TemplatesContext } from "../context/TemplatesContext";
import { MessagesContext } from "../context/MessagesContext";
import MessageCard from "../components/messages/MessageCard";
import { AuthContext } from "../context/AuthContext";
import { setupTooltips } from "../utils";
import Upgrade from "./Upgrade";
import {
  processValuesToObject,
  processValuesToPrompt,
} from "../utils/templates";

const SingleWorkflowConversation = ({ user_workflow_id, conversation_id }) => {
  const refContainer = useRef(null);

  const [page, setPage] = useState(1);
  const [firstLoad, setFirstLoad] = useState(true);

  const { user } = useContext(AuthContext);
  const { user_workflow, getSingleUserWorkflow } =
    useContext(UserWorkflowsContext);
  const { template } = useContext(TemplatesContext);
  const { conversation, getSingleConversation } =
    useContext(ConversationsContext);
  const {
    max,
    messages,
    generating,
    saveMessage,
    clearMessages,
    getConversationMessages,
  } = useContext(MessagesContext);

  useEffect(() => {
    getSingleUserWorkflow(user_workflow_id);
  }, [user_workflow_id]);

  useEffect(() => {
    setupTooltips();
    if (Array.isArray(messages)) {
      handleScrollBottom();
    }
  }, [messages]);

  useEffect(() => {
    fetchMessages();
    getSingleConversation(conversation_id);
  }, [conversation_id, page]);

  const fetchMessages = () => {
    getConversationMessages(conversation_id, { page });
  };

  const handleScrollBottom = () => {
    const container = refContainer.current;
    refContainer.current.scrollTo({
      top: firstLoad
        ? container.offsetHeight * 10
        : container.offsetHeight * messages.length * 2,
    });
    if (firstLoad) setFirstLoad(false);
  };

  const handleScroll = () => {
    const container = refContainer.current;
    const scrollTop = container.scrollTop;
    if (scrollTop === 0 && Array.isArray(messages)) {
      if (messages.length < max) {
        setPage(page + 1);
      }
    }
  };

  const handleMessage = () => {
    const { template } = conversation.user_workflow_template;
    const { values } = conversation.user_workflow_template.user_workflow;
    const prompt = processValuesToPrompt(
      template,
      processValuesToObject(values)
    );
    const payload = {
      content: prompt,
      conversation_id,
      stream: false,
    };
    saveMessage(payload, fetchMessages);
  };

  const renderMessages = () => {
    if (Array.isArray(messages)) {
      let messagesRender = messages.filter(
        (message) => message.role === "assistant"
      );
      return messagesRender.map((message, index) => (
        <MessageCard
          message={message}
          key={message.message_id}
          handleCallback={fetchMessages}
          prevMessage={messages[index - 1]}
        />
      ));
    }
  };

  const renderContinue = () => {
    if (!generating && Array.isArray(messages)) {
      const lastMessage = messages[messages.length - 1];
      if (lastMessage && lastMessage !== null) {
        if (lastMessage.finish_reason === "length") {
          return (
            <button
              className="btn border"
              onClick={() => handleMessage("Continue generating last response")}
            >
              <i className="fa fa-forward me-2"></i> Continue Generating
            </button>
          );
        }
      }
    }
  };

  const renderHeader = () => {
    if (conversation && conversation !== null) {
      return (
        <div
          className="br-10 p-4 mb-3 w-100"
          style={{ position: "absolute", top: 0, left: 0 }}
        >
          <h1 className="h4 mb-0">{conversation.name}</h1>
        </div>
      );
    }
  };

  const renderContent = () => {
    if (!user.has_access) {
      return (
        <Upgrade>
          <p>
            Creating more content with{" "}
            <span className="text-accent">
              Bemodo's AI Content Assitant "Fetch"
            </span>{" "}
            requires an active subscription to BemodoAI.
          </p>
        </Upgrade>
      );
    }

    return (
      <div>
        {renderHeader()}
        <div className="row px-4">
          <div
            id="messages"
            ref={refContainer}
            className="w-100"
            onScroll={handleScroll}
            style={{
              top: 75,
              position: "relative",
            }}
          >
            {renderMessages()}
            {renderContinue()}
            {generating && <div className="spinner-border" />}
          </div>
        </div>
        <div
          className="w-100 p-4 br-10"
          style={{ position: "absolute", bottom: 10, left: 0 }}
        >
          <button
            onClick={handleMessage}
            disabled={generating}
            className="btn btn-primary"
          >
            {generating ? (
              <div className="spinner-border" />
            ) : (
              <span>
                <i className="fa fa-sync me-1"></i> Regenerate
              </span>
            )}
          </button>
        </div>
      </div>
    );
  };

  return (
    <div
      style={{ height: "calc(100vh - 30px)" }}
      className="container-fluid position-relative px-0 card bg-white"
    >
      {renderContent()}
    </div>
  );
};

export default SingleWorkflowConversation;
