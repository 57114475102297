import React, { useReducer, createContext, useContext } from "react";
import AvatarsService from "../services/AvatarsService";
import AvatarsReducer from "../reducers/AvatarsReducer";
import {
  SET_AVATAR,
  CREATE_AVATAR,
  AVATARS_RECEIVED,
  SET_PROPERTY_AVATAR,
  SET_SUPER_FETCH_AVATAR,
  SET_USER_AVATARS,
  SET_PUBLIC_AVATARS,
} from "../types/avatars";
import { navigate } from "@reach/router";
import { ModalContext } from "./ModalContext";
import { HIDE_SPINNER, SHOW_SPINNER } from "../types";
import useTranslations from "../hooks/useTranslations";
import FilesService from "../services/FilesService";
import { AuthContext } from "./AuthContext";

const initialState = {
  spinner: false,
  output: null,
  avatars: null,
  avatar: null,
  publicAvatars: null,
  userAvatars: null,
  super_fetch_avatar: null,
  threadMessages: []
};

export const AvatarsContext = createContext(initialState);

export const AvatarsProvider = ({ children }) => {
  const translations = useTranslations();

  const [state, dispatch] = useReducer(AvatarsReducer, initialState);

  const { showModal, success, alert, clearModal } = useContext(ModalContext);
  const { user } = useContext(AuthContext);

  const handleError = (error) => {
    dispatch({ type: HIDE_SPINNER });
    if (error.response) {
      if (error.response.data) {
        if (error.response.data.error) {
          if (error.response.data.error.code) {
            return alert(`Open AI Error: ${error.response.data.error.message}`);
          }
        }
      }
      if (error.response.status === 412) {
        return alert(
          "This is a premium feature. Please, upgrade your account."
        );
      }
    }
    alert(error);
  };

  const clearAvatars = () => {
    dispatch({ type: AVATARS_RECEIVED, payload: null });
  };

  const getAvatars = () => {
    AvatarsService.getMyAvatars().then((res) => {
      const { avatars } = res.data;
      dispatch({ type: AVATARS_RECEIVED, payload: avatars });
      dispatch({ type: SET_USER_AVATARS, payload: avatars });
    });
  };

  const getSingleAvatar = (avatar_id) => {
    AvatarsService.getSingleAvatar(avatar_id).then((res) => {
      const { avatar } = res.data;
      
      dispatch({ type: SET_AVATAR, payload: avatar });
    });
  };

  const getSuperFetchAvatar = () => {
    return new Promise((resolve, reject) => {
      AvatarsService.getSuperFetchAvatar().then((res) => {
        const { avatar } = res.data;
        dispatch({ type: SET_SUPER_FETCH_AVATAR, payload: avatar });
        resolve(avatar);
      })
      .catch(err => {
        reject(err);
      });
    });
  };

  const getPublicAvatars = () => {
    AvatarsService.getPublicAvatars().then((res) => {
      const { avatars } = res.data;
      dispatch({ type: AVATARS_RECEIVED, payload: avatars });
      dispatch({ type: SET_PUBLIC_AVATARS, payload: avatars });
    });
  };

  const setAvatar = (avatar) => {
    dispatch({ type: SET_AVATAR, payload: avatar });
  };

  const setSuperFetchAvatar = (payload) => {
    dispatch({ type: SET_SUPER_FETCH_AVATAR, payload })
  }

  const createAvatar = () => {
    dispatch({ type: CREATE_AVATAR });
  };

  const setPropertyAvatar = (key, value) => {
    dispatch({ type: SET_PROPERTY_AVATAR, payload: { key, value } });
  };

  const saveAvatarFile = (file, assistant_id, avatar_id) => {
    const formData = FilesService.getFormData(file);
    
    return new Promise((resolve, reject) => {
      AvatarsService.postAvatarFile(formData, assistant_id, avatar_id)
      .then(res => {
        getSingleAvatar(avatar_id, user.user_id);
      })
      .then(res => {
        resolve();
      })
      .catch(err => {
        console.log(err);
        reject();
      });
    })

  }

  const saveAvatar = (avatar) => {
    dispatch({ type: SHOW_SPINNER });
    return new Promise((resolve, reject) => {
      if (isNaN(parseInt(avatar?.avatar_id))) {
        AvatarsService.postAvatar(avatar)
          .then((res) => {
            getSingleAvatar(res.data.avatar.avatar_id, user.user_id);
            dispatch({ type: HIDE_SPINNER });
            if(!avatar.super_fetch_assistant) success(translations.avatars.saved);
            if(avatar.super_fetch_assistant) {
              dispatch({ type: SET_SUPER_FETCH_AVATAR, payload: res.data.avatar })
            }
            getAvatars();
            if (showModal) {
              clearModal();
            }

            resolve(res.data.avatar);
          })
          .catch(handleError);
      } else {
        AvatarsService.putAvatar(avatar)
          .then(() => {
            dispatch({ type: HIDE_SPINNER });
            getSingleAvatar(avatar.avatar_id, user.user_id);
            success(translations.avatars.saved);
            getAvatars();
            if (showModal) {
              clearModal();
            }
            resolve();
          })
          .catch(handleError);
      }
    });
  };

  const syncAvatar = (avatar) => {
    AvatarsService.syncAvatar(avatar.avatar_id)
      .then(() => {
        getAvatars();
        success(translations.avatars.saved);
      })
      .catch(handleError);
  };

  const refineAvatar = (avatar) => {
    dispatch({ type: SHOW_SPINNER });
    AvatarsService.refineAvatar(avatar.avatar_id)
      .then(() => {
        getSingleAvatar(avatar.avatar_id, user.user_id);
        dispatch({ type: HIDE_SPINNER });
        clearModal();
        success(translations.avatars.saved);
      })
      .catch(handleError);
  };

  const combineAvatar = (
    selected_avatar_id,
    target_avatar_id,
    target_training_id,
    name
  ) => {
    AvatarsService.combineAvatar(
      selected_avatar_id,
      target_avatar_id,
      target_training_id,
      name
    )
      .then(() => {
        navigate(`/avatars/${target_avatar_id}`);
        clearModal();
        success(translations.avatars.combined);
      })
      .catch(handleError);
  };

  const deleteAvatarFile = (data) => {
    return new Promise((resolve, reject) => {
      AvatarsService.deleteAvatarFile(data)
      .then(res => {
        getSingleAvatar(state.avatar.avatar_id, user.user_id);
      })
      .then(res => {
        resolve();
      })
      .catch(err => {
        reject();
      });
    });
  }

  const deleteAvatar = (avatar_id) => {
    AvatarsService.deleteAvatar(avatar_id).then(() => {
      success(translations.avatars.deleted);
      getAvatars();
      clearModal();
    });
  };

  return (
    <AvatarsContext.Provider
      value={{
        ...state,
        setAvatar,
        setSuperFetchAvatar,
        syncAvatar,
        getAvatars,
        getSuperFetchAvatar,
        saveAvatar,
        deleteAvatar,
        createAvatar,
        clearAvatars,
        refineAvatar,
        combineAvatar,
        getSingleAvatar,
        getPublicAvatars,
        setPropertyAvatar,
        saveAvatarFile,
        deleteAvatarFile,
      }}
    >
      {children}
    </AvatarsContext.Provider>
  );
};
