import { Link } from "@reach/router";
import React, { useContext, useEffect } from "react";
import { CustomerContext } from "../../../context/CustomerContext";
import useTranslations from "../../../hooks/useTranslations";
import { useState } from "react";
import { OrganizationsContext } from "../../../context/OrganizationsContext";
import userService from "../../../services/UserService";
import useAppInputs from "../../../hooks/global/useAppInputs";
import useWindowSize from "../../../hooks/useWindowSize";

const formInputs = [
  {
    label: 'Name',
    value: '',
    type: 'text',
    isValid: true,
  },
  {
    label: 'Last Name',
    value: '',
    type: 'text',
    isValid: true,
  },
  {
    label: 'Email',
    value: '',
    type: 'text',
    isValid: true,
  },
  {
    label: 'User Type',
    value: 1,
    type: 'select',
    options: [
      {
        label: 'Admin',
        value: 1
      },
      {
        label: 'Manager',
        value: 2
      },
      {
        label: 'Member',
        value: 3
      },
    ],
    isValid: true,
  },
]

const OrganizationUserForm = ({ }) => {
  const [spinner, setSpinner] = useState(false);
  const translations = useTranslations();

  const smallDevice = useWindowSize(450);
  const colInputs = smallDevice ? 12 : 6;

  const { renderAllInputs, validateInputs, inputs, clearAllInputs } = useAppInputs(formInputs, colInputs);
  const { organization, addUserToOrganization } = useContext(OrganizationsContext);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const inputsValid = validateInputs();
    
    if(inputsValid) {
      const { organization_id } = organization;
      const email = inputs[2].value;
      const name = inputs[0].value;
      const last_name = inputs[1].value;
      const user_type_id = inputs[3].value;

      addUserToOrganization({ 
        organization_id, 
        email, 
        name, 
        last_name, 
        user_type_id 
      }, clearAllInputs);
    }
    
    // createUserNoSignUp(customer);
  };


  const renderForm = () => {
    return (
      <form onSubmit={handleSubmit}>
        <div className="row">
          {renderAllInputs()}
        </div> 
        
        <div className="row ">
          <div className="col-12 text-end">
            <button type="submit"  className="btn btn-primary me-2">
              {spinner ? <div className="spinner-border"></div> : translations.admin.users.saveBtn}
            </button>

            <Link
              to="/organization/admin-panel"
              className="btn btn-link text-secondary"
            >
              {translations.admin.users.cancelBtn}
            </Link>
          </div>
        </div>
      </form>
    );
  };

  return (
    <div className="container-fluid h-100 bg-white py-3 rounded-3">
      <div className="row">
        <div className="col-12">
          <h1 className="text-gradient d-inline-block">Add user to "Organization"</h1>
        </div>
      </div>

      <div className="row">
        <div className="col-12 px-0">
          {renderForm()}
        </div>
      </div>
    </div>
  )
};

export default OrganizationUserForm;